import { Link } from "react-router-dom";
import botHead from "../images/bothead.svg";
import botHeadMob from "../images/botheadmob.svg";
const Ad = () => {
  return (
    <>
      <div className=" md:px-16 my-[6em] text-white">
        <div className=" w-full bg-[#171D1E] border border-[#EAEBF033] md:rounded-[20px] px-3 md:px-12 py-8 flex flex-col md:flex-row items-center justify-center md:justify-between">
          <div className=" flex flex-col justify-center items-center text-center md:text-left md:items-start space-y-6 md:space-y-8">
            <p className=" font-Lato text-base font-normal">
              The Era of AI trading
            </p>
            <p className=" font-Lato: text-2xl mdtext-[32px] font-medium leading-7 md:leading-[38px]">
              Boost your portfolio with Bitfin AI powered trading system.
            </p>
            <Link to="/Sign-Up">
              <button className=" px-6 py-3 bg-white text-black md:w-[152px] rounded-[30px] text-center font-Lato font-medium text-sm">
                Get Started Now
              </button>
            </Link>
          </div>
          <img src={botHead} className=" hidden md:block" alt="" />
          <img src={botHeadMob} className=" md:hidden block mt-8" alt="" />
        </div>
      </div>
    </>
  );
};

export default Ad;
