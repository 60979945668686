import { useEffect, useRef } from 'react';


function TickerWidget() {
  const containerRef = useRef(null);

  useEffect(() => {
    const loadExternalScript = async () => {
      try {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
          symbols: [
            { proName: 'BITSTAMP:BTCUSD', title: 'Bitcoin' },
            { proName: 'FOREXCOM:NSXUSD', title: 'US 100' },
            { proName: 'FX_IDC:EURUSD', title: 'EUR/USD' },
            { proName: 'FOREXCOM:SPXUSD', title: 'S&P 500' },
            { proName: 'BITSTAMP:ETHUSD', title: 'Ethereum' }
          ],
          colorTheme: 'dark',
          isTransparent: true,
          showSymbolLogo: false,
          locale: 'en',
          autosize: true,
        });

        containerRef.current.appendChild(script);
      } catch (error) {
        console.error('Error loading external script:', error);
      }
    };

    loadExternalScript();

    return () => {
      const scripts = document.getElementsByTagName('script');
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.includes('embed-widget-tickers.js')) {
          scripts[i].parentNode.removeChild(scripts[i]);
          break;
        }
      }
    };
  }, []);

  return (

    <div className="tradingview-widget-container  font-Nunito" ref={containerRef}>
      <div className="tradingview-widget-container__widget font-Nunito"></div>
    </div>
  );
}

export default TickerWidget;
