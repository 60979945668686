import midstar from "../images/midstar.svg";
import stars from "../images/stars.svg";
import star from "../images/star.svg";
import flare from "../images/flare.svg";
import load from "../images/load.gif";
import { Link, useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useState, useEffect } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
} from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { motion } from "framer-motion";

const SignUp = () => {
  const Navigate = useNavigate();

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth();
  const db = getFirestore(app);

  // states
  const [loadValue, setLoadValue] = useState("No");
  const [Country, setCountry] = useState("");
  const [name, setName] = useState("");
  const [email, setMail] = useState(null);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState(null);
  const [signingUp, setSigningUp] = useState(false);

  // functions
  const getCountry = () => {
    const country = document.getElementById("country");
    setCountry(country.value);
  };

  const validateName = () => {
    const name = document.getElementById("name");
    const namewarn = document.getElementById("nameWarn");
    if (name.value.length < 3) {
      namewarn.classList.replace("hidden", "block");
      namewarn.innerHTML = "Please fill in your name";
      namewarn.style.color = "red";
    } else if (name.value.length > 3) {
      namewarn.classList.replace("block", "hidden");
      namewarn.style.color = "green";
      setName(name.value.trim());
    }
  };

  const validateMail = () => {
    const mail = document.getElementById("mail").value;
    const mailWarn = document.getElementById("mailWarn");

    const checkMail = (mail) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(mail).toLowerCase());
    };
    if (checkMail(mail)) {
      mailWarn.classList.replace("hidden", "block");
      mailWarn.innerHTML = "Valid Mail";
      mailWarn.style.color = "green";
      setMail(mail.trim());
    } else {
      mailWarn.classList.replace("hidden", "block");
      mailWarn.innerHTML = "Invalid Mail";
      mailWarn.style.color = "red";
    }
  };

  const getPhone = () => {
    const phone = document.getElementById("phone").value;
    setPhone(phone.trim());
  };

  const validatePassword = () => {
    const password = document.getElementById("password");
    const passwordwarn = document.getElementById("passwordwarn");
    if (password.value.length < 6) {
      passwordwarn.classList.replace("hidden", "block");
      passwordwarn.innerHTML = "Please pick a valid password";
      passwordwarn.style.color = "red";
    } else {
      passwordwarn.classList.replace("hidden", "block");
      passwordwarn.innerHTML = "Valid password";
      passwordwarn.style.color = "green";
      setPassword(password.value.trim());
    }
  };

  // Function to handle sign-up button click
  const signUpBtn = async () => {
    if (!signingUp) {
      setSigningUp(true); // Disable button
      if (
        name !== "" &&
        email !== "" &&
        Country !== "" &&
        password !== "" &&
        phone !== ""
      ) {
        setLoadValue("Yes");
        try {
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          );
          const user = userCredential.user;

          const userDocs = collection(db, "users");
          const docData = {
            uid: user.uid,
            name: name,
            mail: email,
            country: Country,
            password: password,
            phone: phone,
            plan: "Basic",
            verify: "Pending",
            comissionFee: "false",
            CryptoTotalbal: "0",
            CryptoDeposit: "0",
            CryptoProfits: "0",
            ForexTotalbal: "0",
            ForexDeposit: "0",
            ForexProfits: "0",
            StockTotalbal: "0",
            StockDeposit: "0",
            StockProfits: "0",
            Totalbal: "0",
          };
          await addDoc(userDocs, docData);

          setLoadValue("No");
          setSigningUp(false); // Enable button
          Navigate("/dashboard");
        } catch (error) {
          console.log("error", error);
          alert("Error creating user or saving data");
          setSigningUp(false); // Enable button
        }
      } else {
        alert("Please Complete the form");
        setSigningUp(false); // Enable button
      }
    }
  };

  // useEffect(()=>{
  //   onAuthStateChanged(auth, (user) =>{
  //     if (user){
  //       const uid = user.uid;
  //       // direct to dashboard
  //       Navigate('/dashboard')
  //     }else{

  //     }
  //   })
  // });

  return (
    <motion.div
      initial={{ x: 100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -100, opacity: 0 }}
      transition={{ type: "spring", stiffness: 80, duration: 0.2 }}
    >
      <div className=" px-3 md:px-3 flex flex-col mb-[4em] text-white justify-center items-center relative w-full h-full">
        <img src={star} className=" absolute left-0 top-[-20vh]" alt="" />
        <img src={midstar} className=" centered top-[0vh]" alt="" />
        <img src={stars} className=" absolute right-0 top-[-20vh]" alt="" />
        <img src={flare} className=" mt-28" alt="" />
        <p className=" font-Lato font-semibold text-[32px]">
          Create your account
        </p>
        <p className=" font-Open-Sans font-normal text-base">
          Enter Your Details to enjoy automated trading.
        </p>
        <div className=" flex justify-center items-center flex-col mt-8">
          <label
            className=" font-Open-Sans text-sm text-left w-full font-normal"
            for="first-name"
          >
            Full name
          </label>
          <input
            type="text"
            onInput={validateName}
            id="name"
            placeholder="Enter your full name"
            class=" h-[45px] w-[350px] glower mt-2 border border-[#03AAC74D] z-50 bg-[#121212] mb-6 text-white font-Open-Sans font-normal placeholder:font-Open-Sans text-sm rounded-[20px] block p-2.5"
          />
          <p
            id="nameWarn"
            className=" capitalize hidden font-Open-Sans mr-auto text-xs -mt-5 text-[rgba(255,255,255,0.9)]"
          >
            Please fill in your name
          </p>
          <label
            className=" font-Open-Sans text-sm text-left w-full font-normal"
            for="first-name"
          >
            Email
          </label>
          <input
            type="text"
            onInput={validateMail}
            id="mail"
            placeholder="Enter your email"
            class=" h-[45px] w-[350px] mt-2 border border-[#03AAC74D] z-50 bg-[#121212] mb-6 text-white font-Open-Sans font-normal placeholder:font-Open-Sans text-sm rounded-[20px] block p-2.5"
          />
          <p
            id="mailWarn"
            className=" capitalize hidden mr-auto text-xs -mt-5 text-[rgba(255,255,255,0.9)]"
          >
            Please fill in your Email
          </p>
          <label
            className=" font-Open-Sans text-sm text-left w-full font-normal"
            for="first-name"
          >
            Phone
          </label>
          <input
            type="text"
            id="phone"
            onInput={getPhone}
            placeholder="Enter your Phone Number"
            class=" h-[45px] w-[350px] mt-2 border border-[#03AAC74D] z-50 bg-[#121212] mb-6 text-white font-Open-Sans font-normal placeholder:font-Open-Sans text-sm rounded-[20px] block p-2.5"
          />
          <label
            className=" font-Open-Sans text-sm text-left w-full font-normal"
            for="first-name"
          >
            Select Country
          </label>
          <select
            name="country"
            id="country"
            onChange={getCountry}
            placeholder="Enter your email"
            class=" h-[45px] w-[350px] mt-2 border border-[#03AAC74D] z-50 bg-[#121212] mb-6 text-white font-Open-Sans font-normal placeholder:font-Open-Sans text-sm rounded-[20px] block p-2.5"
          >
            <option value="">Select Country</option>
            <option>Afghanistan</option>
            <option>Albania</option>
            <option>Algeria</option>
            <option>American Samoa</option>
            <option>Andorra</option>
            <option>Angola</option>
            <option>Anguilla</option>
            <option>Antarctica</option>
            <option>Antigua &amp; Barbuda</option>
            <option>Argentina</option>
            <option>Armenia</option>
            <option>Aruba</option>
            <option>Australia</option>
            <option>Austria</option>
            <option>Azerbaijan</option>
            <option>Bahamas, The</option>
            <option>Bahrain</option>
            <option>Bangladesh</option>
            <option>Barbados</option>
            <option>Belarus</option>
            <option>Belgium</option>
            <option>Belize</option>
            <option>Benin</option>
            <option>Bermuda</option>
            <option>Bhutan</option>
            <option>Bolivia</option>
            <option>Bosnia and Herzegovina</option>
            <option>Botswana</option>
            <option>Bouvet Island</option>
            <option>Brazil</option>
            <option>British Indian Ocean Territory</option>
            <option>Brittany</option>
            <option>Brunei Darussalam</option>
            <option>Bulgaria</option>
            <option>Burkina Faso</option>
            <option>Burundi</option>
            <option>Cambodia</option>
            <option>Cameroon</option>
            <option>Canada</option>
            <option>Cape Verde</option>
            <option>Cayman Islands</option>
            <option>Central African Republic</option>
            <option>Chad</option>
            <option>Chile</option>
            <option>China</option>
            <option>Christmas Island</option>
            <option>Cocos (Keeling) Islands</option>
            <option>Colombia</option>
            <option>Comoros</option>
            <option>Congo</option>
            <option>Congo, Democratic Rep. of the</option>
            <option>Cook Islands</option>
            <option>Costa Rica</option>
            <option>Croatia</option>
            <option>Cuba</option>
            <option>Cyprus</option>
            <option>Czech Republic</option>
            <option>Denmark</option>
            <option>Djibouti</option>
            <option>Dominica</option>
            <option>Dominican Republic</option>
            <option>Ecuador</option>
            <option>Egypt</option>
            <option>El Salvador</option>
            <option>Equatorial Guinea</option>
            <option>Eritrea</option>
            <option>Estonia</option>
            <option>Ethiopia</option>
            <option>Falkland Islands (Malvinas)</option>
            <option>Faroe Islands</option>
            <option>Fiji</option>
            <option>Finland</option>
            <option>France</option>
            <option>French Polynesia</option>
            <option>French Southern Territories - TF</option>
            <option>Gabon</option>
            <option>Gambia, the</option>
            <option>Georgia</option>
            <option>Germany</option>
            <option>Ghana</option>
            <option>Gibraltar</option>
            <option>Greece</option>
            <option>Greenland</option>
            <option>Grenada</option>
            <option>Guam</option>
            <option>Guatemala</option>
            <option>Guiana, French</option>
            <option>Guinea</option>
            <option>Guinea-Bissau</option>
            <option>Guinea, Equatorial</option>
            <option>Guyana</option>
            <option>Haiti</option>
            <option>Heard and McDonald Islands</option>
            <option>Honduras</option>
            <option>Hong Kong, (China)</option>
            <option>Hungary</option>
            <option>Iceland</option>
            <option>India</option>
            <option>Indonesia</option>
            <option>Iran, Islamic Republic of</option>
            <option>Iraq</option>
            <option>Ireland</option>
            <option>Israel</option>
            <option>Italy</option>
            <option>Ivory Coast (see Cote d'Ivoire)</option>
            <option>Jamaica</option>
            <option>Japan</option>
            <option>Jordan</option>
            <option>Kazakhstan</option>
            <option>Kenya</option>
            <option>Kiribati</option>
            <option>Korea, (South) Republic of</option>
            <option>Korea, Demo. People's Rep. of</option>
            <option>Kuwait</option>
            <option>Kyrgyzstan</option>
            <option>Lao People's Democratic Republic</option>
            <option>Latvia</option>
            <option>Lebanon</option>
            <option>Lesotho</option>
            <option>Liberia</option>
            <option>Libyan Arab Jamahiriya</option>
            <option>Liechtenstein</option>
            <option>Lithuania</option>
            <option>Luxembourg</option>
            <option>Macao, (China)</option>
            <option>Macedonia, TFYR</option>
            <option>Madagascar</option>
            <option>Malawi</option>
            <option>Malaysia</option>
            <option>Maldives</option>
            <option>Mali</option>
            <option>Malta</option>
            <option>Marshall Islands</option>
            <option>Martinique</option>
            <option>Mauritania</option>
            <option>Mauritius</option>
            <option>Mayotte</option>
            <option>Mexico</option>
            <option>Micronesia, Federated States of</option>
            <option>Moldova, Republic of</option>
            <option>Monaco</option>
            <option>Mongolia</option>
            <option>Montserrat</option>
            <option>Morocco</option>
            <option>Mozambique</option>
            <option>Myanmar (ex-Burma)</option>
            <option>Namibia</option>
            <option>Nauru</option>
            <option>Nepal</option>
            <option>Netherlands</option>
            <option>Netherlands Antilles</option>
            <option>New Caledonia</option>
            <option>New Zealand</option>
            <option>Nicaragua</option>
            <option>Niger</option>
            <option>Nigeria</option>
            <option>Niue</option>
            <option>Norfolk Island</option>
            <option>Northern Mariana Islands</option>
            <option>Norway</option>
            <option>Oman</option>
            <option>Pakistan</option>
            <option>Palau</option>
            <option>Panama</option>
            <option>Papua New Guinea</option>
            <option>Paraguay</option>
            <option>Peru</option>
            <option>Philippines</option>
            <option>Pitcairn Island</option>
            <option>Poland</option>
            <option>Portugal</option>
            <option>Puerto Rico</option>
            <option>Qatar</option>
            <option>Reunion</option>
            <option>Romania</option>
            <option>Russia (Russian Federation)</option>
            <option>Rwanda</option>
            <option>S. Georgia and S. Sandwich Is.</option>
            <option>Saint Helena</option>
            <option>Saint Kitts and Nevis</option>
            <option>Saint Lucia</option>
            <option>Saint Pierre and Miquelon</option>
            <option>Saint Pierre and Miquelon</option>
            <option>Saint Pierre and Miquelon</option>
            <option>Saint Vincent and the Grenadines</option>
            <option>Samoa</option>
            <option>San Marino</option>
            <option>Sao Tome and Principe</option>
            <option>Saudi Arabia</option>
            <option>Senegal</option>
            <option>Seychelles</option>
            <option>Sierra Leone</option>
            <option>Singapore</option>
            <option>Slovakia</option>
            <option>Slovenia</option>
            <option>Solomon Islands</option>
            <option>Somalia</option>
            <option>South Africa</option>
            <option>Spain</option>
            <option>Sri Lanka (ex-Ceilan)</option>
            <option>Sudan</option>
            <option>Suriname</option>
            <option>Svalbard and Jan Mayen Islands</option>
            <option>Swaziland</option>
            <option>Sweden</option>
            <option>Switzerland</option>
            <option>Syrian Arab Republic</option>
            <option>Taiwan</option>
            <option>Tajikistan</option>
            <option>Tanzania, United Republic of</option>
            <option>Thailand</option>
            <option>Timor-Leste (East Timor)</option>
            <option>Togo</option>
            <option>Tokelau</option>
            <option>Tonga</option>
            <option>Trinidad &amp; Tobago</option>
            <option>Tunisia</option>
            <option>Turkey</option>
            <option>Turkmenistan</option>
            <option>Turks and Caicos Islands</option>
            <option>Tuvalu</option>
            <option>Uganda</option>
            <option>Ukraine</option>
            <option>United Arab Emirates</option>
            <option>United States</option>
            <option>United Kingdom</option>
            <option>Uruguay</option>
            <option>US Minor Outlying Islands</option>
            <option>Uzbekistan</option>
            <option>Vanuatu</option>
            <option>Vatican City State (Holy See)</option>
            <option>Venezuela</option>
            <option>Viet Nam</option>
            <option>Virgin Islands, British</option>
            <option>Virgin Islands, U.S.</option>
            <option>Wallis and Futuna</option>
            <option>Western Sahara</option>
            <option>Yemen</option>
            <option>Zambia</option>
            <option>Zimbabwe</option>
          </select>
          <label
            className=" font-Open-Sans text-sm text-left w-full font-normal"
            for="first-name"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            onInput={validatePassword}
            placeholder="Password must be 6+ characters"
            class=" h-[45px] w-[350px] mt-2 border border-[#03AAC74D] z-50 bg-[#121212] mb-6 text-white font-Open-Sans font-normal placeholder:font-Open-Sans text-sm rounded-[20px] block p-2.5"
          />
          <p
            id="passwordwarn"
            className=" capitalize hidden mr-auto text-xs -mt-5 text-[rgba(255,255,255,0.9)]"
          >
            Please fill in your Password
          </p>
          <label
            className=" font-Open-Sans text-sm text-left w-full font-normal"
            for="first-name"
          >
            Confirm password
          </label>
          <input
            type="text"
            placeholder="Re-enter your password"
            class=" h-[45px] w-[350px] mt-2 border border-[#03AAC74D] z-50 bg-[#121212] mb-6 text-white font-Open-Sans font-normal placeholder:font-Open-Sans text-sm rounded-[20px] block p-2.5"
          />
          <label className="flex items-center w-full">
            <input type="checkbox" className=" h-4 w-4" />
            <span className="ml-2 text-sm font-Open-Sans font-medium">
              I agree with Bitfin Privacy Policy
            </span>
          </label>
          <button
            onClick={signUpBtn}
            className=" w-full flex h-[45px] mt-6 rounded-[20px] bg-[#03AAC7] hover:bg-opacity-75 transition-all justify-center items-center"
          >
            {loadValue === "No" && (
              <p className=" font-Lato text-base text-white font-semibold">
                Create account
              </p>
            )}
            {loadValue === "Yes" && (
              <img src={load} className=" w-6 h-6" alt="" />
            )}
          </button>
          <p className=" w-full text-center mt-4 font-Open-Sans text-sm text-[#FFFFFFB2]">
            Already have an account?
            <Link to="/Sign-In">
              <span className=" font-Lato font-semibold text-[#03AAC7]">
                Sign in
              </span>
            </Link>
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default SignUp;
