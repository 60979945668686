const Testimony = () => {
  return (
    <>
      <div className=" hidden md:block px-3 md:px-16 my-[6em] text-white">
        <p className=" font-Lato font-medium text-2xl md:text-5xl text-center">
          What people are saying
        </p>
        <div className=" md:w-full flex flex-row justify-between items-start mt-[2em] md:mt-[4em] overflow-auto relative">
          <div className=" w-[100%] h-[100%] absolute hidden md:block top-0 bg-gradient-to-b from-transparent via-[#12121293] to-[#121212]"></div>
          <div className=" flex md:flex-col flex-row w-[100%] md:w-1/4 pr-5 space-y-5">
            <div className=" bg-[#1e1e1e] rounded-[10px] px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">John Smith</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                New York, USA
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] text-[#FFFFFF80]">
                I'm a beginner trader and I was looking for a way to automate my
                trading. I found Bitfin AI Trading and I'm really happy with the
                results. I've been able to grow my account by 10% in the past 6
                months.
              </p>
            </div>
            <div className=" bg-[#1e1e1e] rounded-[10px] px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Sandra Lee</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Singapore
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] text-[#FFFFFF80]">
                I'm a female trader and I was looking for a company that
                understands the challenges that women face in the financial
                markets. I've been very impressed with the support from Bitfin.
                They have a team of experienced female traders who are always
                available to answer my questions.
              </p>
            </div>
            <div className=" bg-[#1e1e1e] rounded-[10px] px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">
                Alejandro Perez
              </p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Argentina
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] text-[#FFFFFF80]">
                I'm a beginner trader and I was looking for a way to get started
                without risking too much money. I found Bitfin to be very easy
                to use and I've been able to make some profits. I'm still
                learning but I'm confident that I can use this platform to
                achieve my trading goals.
              </p>
            </div>
          </div>

          <div className=" flex md:flex-col flex-row w-[100%] md:w-1/4 pr-5 space-y-5">
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Carlos Garcia</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Mexico City, Mexico
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] text-[#FFFFFF80]">
                I'm a young entrepreneur and I was looking for a way to invest
                my money in the latest technologies. I found Bitfin to be very
                innovative and I'm confident that they can help me to achieve my
                investment goals.
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Amit Sharma</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                India
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] text-[#FFFFFF80]">
                I'm a student and I was looking for a way to invest my money for
                the future. I found the managed accounts service from AI Trading
                Company to be very affordable and I'm confident that my money is
                in good hands. I'm already seeing positive returns.
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Lisa Nguyen</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Vietnam
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] text-[#FFFFFF80]">
                I'm a stay-at-home mom and I was looking for a way to make some
                extra money. I've been using Bitfin and I've been able to make
                some profits. I'm able to do this from home and it's a great way
                to supplement my income.
              </p>
            </div>
          </div>

          <div className=" flex md:flex-col flex-row w-[100%] md:w-1/4 pr-5 space-y-5">
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Mohammed Ahmed</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Dubai, UAE
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] text-[#FFFFFF80]">
                I'm an international trader and I was looking for a company that
                can help me trade in different markets. Bitfin offers a variety
                of trading solutions that can be used in different markets. I'm
                very happy with the service and I would recommend it to other
                international traders."
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Sofia Ivanova</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Bulgaria
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] text-[#FFFFFF80]">
                I'm a working professional and I was looking for a way to invest
                my money without having to spend too much time on it. I found
                Bitfin to be very convenient and I'm confident that my money is
                in good hands. I'm also able to get regular updates on the
                performance of my account.
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Yann LeCun</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                France
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] text-[#FFFFFF80]">
                I'm a risk-averse investor and I was looking for a way to invest
                my money without taking too much risk. I found Bitfin to be very
                conservative and I'm confident that my money is safe. I'm still
                seeing positive returns.
              </p>
            </div>
          </div>

          <div className=" flex md:flex-col flex-row w-[100%] md:w-1/4 pr-5 space-y-5">
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Kris Wu</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                China
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] text-[#FFFFFF80]">
                I'm a celebrity and I'm looking for a way to manage my finances.
                I found Bitfin to be very reliable and I'm confident that my
                money is in good hands. I'm also able to get personalized advice
                from their team of experts.
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Sally Jones</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                San Francisco, CA
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] text-[#FFFFFF80]">
                I'm a professor of computer science and I'm interested in the
                application of artificial intelligence to the financial markets.
                I've been impressed with the work of Bitfin and I believe that
                they are at the forefront of this field. I'm excited to see what
                they will accomplish in the future.
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Aisha Khan</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Islamabad, Pakistan
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] text-[#FFFFFF80]">
                I'm an experienced trader but I was looking for a way to improve
                my trading results. I've been using Bitfin and I've learned a
                lot about trading psychology and risk management. I'm confident
                that they will help me to become a better trader.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" px-3 flex-col flex justify-center items-center w-full md:hidden my-[6em] text-white">
        <p className=" font-Lato text-center font-normal text-[32px]">
          What people are saying
        </p>
        <div className=" overflow-x-auto max-w-[100%] scrollbar-hide">
          <div className=" flex flex-row space-x-5 mt-[2em]">
            <div className=" bg-[#1e1e1e] rounded-[10px] h-min px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">John Smith</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                New York, USA
              </p>
              <p className=" font-Open-Sans text-sm  w-[250px] h-min font-normal leading-[22px] text-[#FFFFFF80]">
                I'm a beginner trader and I was looking for a way to automate my
                trading. I found Bitfin AI Trading and I'm really happy with the
                results. I've been able to grow my account by 10% in the past 6
                months.
              </p>
            </div>

            <div className=" bg-[#1e1e1e] rounded-[10px] h-min px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Sandra Lee</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Singapore
              </p>
              <p className=" font-Open-Sans text-sm w-[250px] h-min font-normal leading-[22px] text-[#FFFFFF80]">
                I'm a female trader and I was looking for a company that
                understands the challenges that women face in the financial
                markets. I've been very impressed with the support from Bitfin.
                They have a team of experienced female traders who are always
                available to answer my questions.
              </p>
            </div>

            <div className=" bg-[#1e1e1e] rounded-[10px] h-min px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">
                Alejandro Perez
              </p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Argentina
              </p>
              <p className=" font-Open-Sans text-sm w-[250px] h-min font-normal leading-[22px] text-[#FFFFFF80]">
                I'm a beginner trader and I was looking for a way to get started
                without risking too much money. I found Bitfin to be very easy
                to use and I've been able to make some profits. I'm still
                learning but I'm confident that I can use this platform to
                achieve my trading goals.
              </p>
            </div>

            <div className=" w-full bg-[#1e1e1e] rounded-[10px] h-min px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Carlos Garcia</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Mexico City, Mexico
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] w-[250px] text-[#FFFFFF80]">
                I'm a young entrepreneur and I was looking for a way to invest
                my money in the latest technologies. I found Bitfin to be very
                innovative and I'm confident that they can help me to achieve my
                investment goals.
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] h-min px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Amit Sharma</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                India
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] w-[250px] text-[#FFFFFF80]">
                I'm a student and I was looking for a way to invest my money for
                the future. I found the managed accounts service from AI Trading
                Company to be very affordable and I'm confident that my money is
                in good hands. I'm already seeing positive returns.
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] h-min px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Lisa Nguyen</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Vietnam
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] w-[250px] text-[#FFFFFF80]">
                I'm a stay-at-home mom and I was looking for a way to make some
                extra money. I've been using Bitfin and I've been able to make
                some profits. I'm able to do this from home and it's a great way
                to supplement my income.
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] h-min px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Mohammed Ahmed</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Dubai, UAE
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] w-[250px] text-[#FFFFFF80]">
                I'm an international trader and I was looking for a company that
                can help me trade in different markets. Bitfin offers a variety
                of trading solutions that can be used in different markets. I'm
                very happy with the service and I would recommend it to other
                international traders."
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] h-min px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Sofia Ivanova</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Bulgaria
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] w-[250px] text-[#FFFFFF80]">
                I'm a working professional and I was looking for a way to invest
                my money without having to spend too much time on it. I found
                Bitfin to be very convenient and I'm confident that my money is
                in good hands. I'm also able to get regular updates on the
                performance of my account.
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] h-min px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Yann LeCun</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                France
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] w-[250px] text-[#FFFFFF80]">
                I'm a risk-averse investor and I was looking for a way to invest
                my money without taking too much risk. I found Bitfin to be very
                conservative and I'm confident that my money is safe. I'm still
                seeing positive returns.
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] h-min px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Kris Wu</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                China
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] w-[250px] text-[#FFFFFF80]">
                I'm a celebrity and I'm looking for a way to manage my finances.
                I found Bitfin to be very reliable and I'm confident that my
                money is in good hands. I'm also able to get personalized advice
                from their team of experts.
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] h-min px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Sally Jones</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                San Francisco, CA
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] w-[250px] text-[#FFFFFF80]">
                I'm a professor of computer science and I'm interested in the
                application of artificial intelligence to the financial markets.
                I've been impressed with the work of Bitfin and I believe that
                they are at the forefront of this field. I'm excited to see what
                they will accomplish in the future.
              </p>
            </div>
            <div className=" w-full bg-[#1e1e1e] rounded-[10px] h-min px-5 py-4 space-y-2">
              <p className=" font-Lato text-sm font-semibold">Aisha Khan</p>
              <p className=" text-[#A9A9A9] font-Open-Sans font-semibold text-xs">
                Islamabad, Pakistan
              </p>
              <p className=" font-Open-Sans text-sm font-normal leading-[22px] w-[250px] text-[#FFFFFF80]">
                I'm an experienced trader but I was looking for a way to improve
                my trading results. I've been using Bitfin and I've learned a
                lot about trading psychology and risk management. I'm confident
                that they will help me to become a better trader.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimony;
