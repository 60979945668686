import { motion } from "framer-motion";
import AdOne from "../comps/adone";
import Footer from "../comps/footer";
import Navbar from "../comps/navbar";
import midstar from "../images/midstar.svg";

const HowitworksPage = () => {
  return (
    <>
      <Navbar />
      <motion.div
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -100, opacity: 0 }}
        transition={{ type: "spring", stiffness: 80, duration: 0.2 }}
      >
        <img src={midstar} className=" -z-10 centered top-[0vh]" alt="" />
        <div className=" md:px-0 flex flex-col justify-center items-center space-y-7 text-white">
          <span className=" bg-[#03AAC7] text-black py-1 px-4 font-Open-Sans text-sm md:text-base font-normal rounded-[40px] mt-[3em]">
            How it works
          </span>
          <p className=" font-Lato text-[32px] md:text-5xl text-white font-semibold leading-9 text-center md:px-[20%]">
            How Bitfin works{" "}
          </p>
          <p className=" text-lg font-Open-Sans font-normal text-center leading-6 md:px-[15%]">
            Our website makes AI trading accessible, efficient, and profitable
            for everyone. We do this by providing a suite of services that are
            tailored to the specific needs of each trader.
          </p>
          <button className=" bg-[#03AAC7] px-8 py-3 rounded-[30px] font-Lato text-lg md:text-xl font-normal glower">
            Get started
          </button>
        </div>
        <div className=" px-3 md:px-16 z-10 pt-8 bg-[#121212] -translate-y-14 text-white">
          <p className=" text-2xl md:text-4xl font-Lato font-medium">
            How our website works:
          </p>
          <p className=" text-lg md:text-2xl font-Lato font-medium mt-6">
            Algorithmic trading
          </p>
          <p className=" font-Open-Sans font-normal text-sm text-[#FFFFFFCC] mt-2">
            Our algorithms are designed to scan the market for trading
            opportunities and execute trades automatically. This can help
            traders to make more informed decisions, automate their trading, and
            manage risk.
          </p>
          <p className="font-Open-Sans font-normal text-sm text-[#FFFFFFCC]">
            Here are benefits of AI in our website:
          </p>
          <div className="font-Open-Sans font-normal text-sm space-y-3 text-[#FFFFFFCC] mt-2">
            <span className="flex flex-row space-x-1">
              <p>1.</p>
              <p className="">
                Our algorithmic trading solutions are powered by artificial
                intelligence (AI) and machine learning. This means that our
                algorithms are constantly learning and improving, which allows
                them to find trading opportunities that would be difficult to
                identify for human traders.
              </p>
            </span>
            <span className="flex flex-row space-x-1">
              <p>2.</p>
              <p className="">
                Our algorithms are also designed to be highly customizable. This
                means that we can tailor our solutions to meet the specific
                needs of each trader, such as their risk tolerance, trading
                style, and investment goals.
              </p>
            </span>
            <span className="flex flex-row space-x-1">
              <p>3.</p>
              <p className="">
                In addition to scanning the market for trading opportunities,
                our algorithms can also execute trades automatically. This can
                help traders to automate their trading and free up their time
                for other activities.
              </p>
            </span>
            <span className="flex flex-row space-x-1">
              <p>4.</p>
              <p className="">
                Finally, our algorithmic trading solutions can help traders to
                manage risk. This is done by identifying and mitigating
                potential risks, such as market volatility and liquidity issues.
              </p>
            </span>
          </div>

          <div className=" mt-[2em]">
            <p className=" text-lg md:text-2xl font-Lato font-medium mt-6">
              Here are some of the key features of our website:
            </p>
            <div className="font-Open-Sans font-normal text-sm space-y-3 text-[#FFFFFFCC] mt-2">
              <span className="flex flex-row space-x-1">
                <p>1.</p>
                <p className="">
                  Ease of use: Our website is designed to be easy to use, even
                  for beginners.
                </p>
              </span>
              <span className="flex flex-row space-x-1">
                <p>2.</p>
                <p className="">
                  Affordability: We offer a variety of pricing options to fit
                  your budget.
                </p>
              </span>
              <span className="flex flex-row space-x-1">
                <p>3.</p>
                <p className="">
                  Expertise: Our team of experts has years of experience in AI
                  trading.
                </p>
              </span>
              <span className="flex flex-row space-x-1">
                <p>4.</p>
                <p className="">
                  Security: Your personal information is safe and secure.
                </p>
              </span>
              <p className=" font-Open-Sans font-normal text-sm text-[#FFFFFFCC] mt-6">
                We believe that everyone should have the opportunity to benefit
                from the power of AI trading. Our website makes it easy for
                anyone to get started with AI trading, regardless of their
                experience level.
              </p>
            </div>
          </div>
        </div>
        <AdOne />
        <Footer />
      </motion.div>
    </>
  );
};

export default HowitworksPage;
