import {  useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { PageContext } from "../contexts/pageContext";
import illustration from '../images/Illustrations.png';


const TransactionHistory = () => {
    //states
    const [page, setPage] = useContext(PageContext);
    const [transactionData, setTransactionData] = useState([]);

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const auth = getAuth();
    const db = getFirestore(app)

    useEffect(()=>{
      onAuthStateChanged(auth, (user) =>{
          if (user){
            const uid = user.uid;
            const colRef = collection(db, 'transactions');
            const q = query(colRef, where('uid', '==', uid),  orderBy("date", "desc"))
            onSnapshot(q,(snapshot)=>{
              const transactions = [];
              snapshot.docs.forEach((doc)=>{
                  transactions.push({id:doc.id, ...doc.data()})
              });
              setTransactionData(transactions);
            })
          }else{

          }
      });
  },[page, auth, db]);

  function CheckStat(){
        const checkClass = document.querySelectorAll('.check');
        for(let i = 0; i < checkClass.length; i++){
            if(checkClass[i].innerText === 'Successful'){
                checkClass[i].classList.add('bg-[#B1FFCC]', 'text-[#0A532D]')
            }
            else if(checkClass[i].innerText === 'Pending'){
                checkClass[i].classList.add('bg-orange-700')
            }
        };

        const checkType = document.querySelectorAll('.type');
        for(let i = 0; i < checkType.length; i++){
            if(checkType[i].innerText === 'Withdrawal'){
                checkType[i].classList.add('text-[#F13645]')
            }
            else if(checkType[i].innerText === 'Deposit'){
                checkType[i].classList.add('text-[#0CD16B]')
            }
        };
    };

    useEffect(()=>{
        CheckStat();
    })

    return ( 
        <>
        <div className="w-full mx-auto overflow-auto scrollbar-hide">
            <table className="min-w-full text-white">
                <thead className="">
                <tr className=" bg-[#1E1E1E] ">
                    <th className="px-6 py-6 text-left font-Nunito-Sans font-normal text-lg rounded-l-[15px]">Date</th>
                    <th className="px-6 py-6 text-left font-Nunito-Sans font-normal text-lg">Type</th>
                    <th className="px-6 py-6 text-left font-Nunito-Sans font-normal text-lg">Amount</th>
                    <th className="px-6 py-6 text-left font-Nunito-Sans font-normal text-lg">Time</th>
                    <th className="px-6 py-6 text-left font-Nunito-Sans font-normal text-lg rounded-r-[15px]">Status</th>
                </tr>
                </thead>
                { transactionData && <tbody>
                {transactionData.map((doc) => {
                        return (
                            <tr key={doc.id} className="bg-transparent border-b border-[#EAEBF01A]">
                            <td className="px-6 py-4 font-Open-Sans text-base font-normal">{'$' + doc.amount}</td>
                            <td className="type px-6 py-4 font-Open-Sans text-base font-normal">{doc.transactionType}</td>
                            <td className="px-6 py-4 font-Open-Sans text-base font-normal">{doc.date}</td>
                            <td className="px-6 py-4 font-Open-Sans text-base font-normal">{doc.time}</td>
                            <td className="px-6 py-4 font-Open-Sans text-base font-normal"><button className="check rounded-[20px] w-[100px] py-2 text-sm text-center">{doc.transactionState}</button></td>
                            </tr>
                        );
                    })}
                </tbody>}
            </table>
            { !transactionData && <div className=" flex flex-col justify-center items-center w-full">
                <img src={ illustration } className=" md:mr-[15%]" alt="" />
                <p className=" font-Lato font-bold text-2xl text-white  md:mr-[15%]">No transaction yet</p>
                <p className=" text-[#FFFFFF80] font-Open-Sans text-base mt-3 max-w-[300px] md:mr-[15%] font-normal text-center">You have no notifications right now. make a transaction and check back later.</p>
            </div>}
        </div>
        
        </>
     );
}
 
export default TransactionHistory;