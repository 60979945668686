import axios from 'axios';
import { useState, useEffect } from 'react';

const Markets = () => {
    const [cryptoData, setCryptoData] = useState([]);

    useEffect(() => {
        // Define the API URL
        const apiUrl =
          'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en';
    
        // Fetch data from the API
        axios.get(apiUrl)
          .then((response) => {
            // Limit the data to the first 10 entries
            const limitedData = response.data.slice(0, 10);
    
            // Store the limited data in state
            setCryptoData(limitedData);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }, []); // Empty dependency array ensures this runs once when the component mounts

      // Function to determine CSS class based on price change
        const getPriceChangeClass = (change) => {
            return change >= 0 ? 'positive' : 'negative';
        };

      // console.log(cryptoData);
    return ( 
        <>
        <div className=" px-3 md:px-16 my-[6em] text-white">
            <p className=" font-Lato font-medium text-2xl md:text-[32px]">Market Prices</p>
            <div className=" w-full p-3 border mt-8 rounded-[20px] overflow-x-auto scrollbar-hide border-[#EAEBF01A] relative">
            <table className=' w-[100%] font-Open-Sans text-sm md:text-base text-white font-normal'>
              <thead className=''>
                <tr className=' border-b border-[#EAEBF01A]'>
                    <th className=' text-left font-Lato font-semibold text-xs md:text-base py-5 md:pl-[50px]'>Crypto</th>
                    <th className=' text-center font-Lato font-semibold text-xs md:text-base py-5 '>Current Price</th>
                    <th className=' text-center font-Lato font-semibold text-xs md:text-base py-5 md-max:hidden'>Low (24h)</th>
                    <th className=' text-center font-Lato font-semibold text-xs md:text-base py-5 md-max:hidden'>High (24h)</th>
                    <th className=' text-center font-Lato font-semibold text-xs md:text-base py-5 '>Price Change(24h)</th>
                </tr>
              </thead>
              <tbody className='font-Open-Sans text-base text-white text-center font-normal'>
                {cryptoData.map((crypto, index) => (
                    <tr className=' space-x-4' key={index}>
                    <td className=' flex justify-start items-end mx-auto w-full'>
                    <div className="crypto-info flex w-auto md:pl-[50px] py-3 space-x-2 items-center justify-center">
                        <img
                            src={crypto.image}
                            alt={`${crypto.name} logo`}
                            className=' w-[20px] h-[20px] md:w-[32px] md:h-[32px] rounded-[50%]'
                        />
                        <p className=' font-Open-Sans font-normal md:text-base text-xs'>{crypto.name}</p>
                    </div>
                    </td>
                    <td className='font-Open-Sans text-xs md:text-base py-3  text-white font-normal' >${crypto.current_price}</td>
                    <td className=' font-Open-Sans text-xs md:text-base py-3 md-max:hidden text-white font-normal'>${crypto.low_24h}</td>
                    <td className=' font-Open-Sans text-xs md:text-base py-3 md-max:hidden text-white font-normal'>${crypto.high_24h}</td>
                    <td className={`${getPriceChangeClass(crypto.price_change_percentage_24h)} text-center md:text-le font-Open-Sans text-xs md:text-base`}
>{crypto.price_change_percentage_24h}%</td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        </div>
        </>
     );
}
 
export default Markets;