import AboutPage from "./pages/aboutPage";
import ContactPage from "./pages/contactPage";
import Dashboard from "./pages/dashboard";
import HowitworksPage from "./pages/howitworksPage";
import Landing from "./pages/landing";
import Legal from "./pages/legal";
import Signin from "./pages/signIn";
import SignUp from "./pages/signUp";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import FgPass from "./pages/fgPass";

function App() {
  const location = useLocation();

  return (
    <>
      <AnimatePresence mode="wait">
        <Routes key={location.pathname} location={location}>
          <Route path="/" element={<Landing />} />
          <Route path="/Contact" element={<ContactPage />} />
          <Route path="/How-it-works" element={<HowitworksPage />} />
          <Route path="/Legal" element={<Legal />} />
          <Route path="/About" element={<AboutPage />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Sign-Up" element={<SignUp />} />
          <Route path="/Sign-In" element={<Signin />} />
          <Route path="/Forgot-Password" element={<FgPass />} />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
