import fire from '../images/fire.svg';
import flame from '../images/flame.svg';
import fireMob from '../images/firemob.svg';
import flameMob from '../images/flamemob.svg';
import { Link } from 'react-router-dom';


const AdOne = () => {
    return ( 
        <>
        <div className=" md:px-16 mt-[6em] md:mt-0 md:my-[6em] text-white">
            <div className=" w-full h-[328px] bg-[#03AAC7] text-center px-5 md:px-0 md:rounded-[20px] relative flex justify-center items-center flex-col">
                <p className=" font-Lato text-[#FFFFFF] font-semibold text-[32px] md:text-[40px] mt-2">Join a new generation of Traders</p>
                <p className=" font-Open-Sans text-base md:text-xl font-normal">Trade on a platform trusted by millions of users.</p>
                <Link to='/Sign-Up'><button className=" text-center text-black mt-3 md:mt-4 bg-white px-8 py-4 rounded-[10px] font-Open-Sans text-lg font-semibold">Get started</button></Link>
                <img src={ fire } className=' absolute hidden md:block top-0 left-8' alt="" />
                <img src={ flame } className=' absolute hidden md:block bottom-0 right-8' alt="" />
                <img src={ fireMob } className=' absolute md:hidden block top-0 left-0' alt="" />
                <img src={ flameMob } className=' absolute md:hidden block bottom-0 right-0' alt="" />
            </div>
        </div>
        </>
     );
}
 
export default AdOne;