import { motion } from "framer-motion";
import AdOne from "../comps/adone";
import Footer from "../comps/footer";
import Navbar from "../comps/navbar";
import midstar from "../images/midstar.svg";

const Legal = () => {
  return (
    <>
      <Navbar />
      <motion.div
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -100, opacity: 0 }}
        transition={{ type: "spring", stiffness: 80, duration: 0.2 }}
      >
        <img src={midstar} className=" -z-10 centered top-[0vh]" alt="" />
        <div className=" md:px-0 flex flex-col justify-center items-center space-y-7 text-white">
          <span className=" bg-[#03AAC7] text-black py-1 px-4 font-Open-Sans text-sm md:text-base font-normal rounded-[40px] mt-[3em]">
            Legal
          </span>
          <p className=" font-Lato text-[32px] md:text-5xl text-white font-semibold leading-9 text-center md:px-[20%]">
            Terms and Conditions
          </p>
          <p className=" text-lg font-Open-Sans font-normal text-center leading-6 md:px-[15%]">
            By accessing our website or placing an order, you agree to our terms
            of service. We are not liable for any damages arising from the use
            of our website or materials on it. You are responsible for any costs
            incurred if you use materials from our website. We reserve the right
            to change prices and revise our resources usage policy at any time.
          </p>
          <button className=" bg-[#03AAC7] px-8 py-3 rounded-[30px] font-Lato text-lg md:text-xl font-normal glower">
            Get started
          </button>
        </div>
        <div className=" px-3 md:px-16 z-10 pt-8 bg-[#121212] -translate-y-14 text-white">
          <div className=" font-Open-Sans font-normal text-sm text-[#FFFFFFCC] space-y-3">
            <p className=" font-Lato text-lg md:text-2xl font-medium text-white">
              License
            </p>
            <p>
              Bitfin grants you a revocable, non-exclusive, non-transferable,
              limited license to use our service strictly in accordance with the
              terms of this Agreement. These Terms & Conditions are a contract
              between you and Bitfin (referred to in these Terms & Conditions as
              "Bitfin", "us", "we" or "our"), the provider of the Bitfin website
              and the services accessible from the Bitfin website.(which are
              collectively referred to in these Terms & Conditions as the
              "Bitfin Service"). You are agreeing to be bound by these Terms &
              Conditions. If you do not agree to these Terms & Conditions,
              please do not use the Service. In these Terms & Conditions, "you"
              refers both to you as an individual and to the entity you
              represent. If you violate any of these Terms & Conditions, we
              reserve the right to cancel your account or block access to your
              account without notice.
            </p>
          </div>
          <div className=" font-Open-Sans font-normal text-sm text-[#FFFFFFCC] mt-[1em] space-y-3">
            <p className=" font-Lato text-lg md:text-2xl font-medium text-white">
              Definitions And Key Terms
            </p>
            <p>
              Cookie: small amount of data generated by a website and saved by
              your web browser. It is used to identify your browser, provide
              analytics, remember information about you such as your language
              preference or login information
            </p>
            <p>
              Company: when this policy mentions "Company," "we," "us," or
              "our," it refers toBitfin that is responsible for your information
              under this Privacy Policy.
            </p>
            <p>
              Country: where Bitfin or the owners/founders of Bitfin are based,
              in this case is America.
            </p>
            <p>
              Customer: refers to the company, organization or person that signs
              up to use the Bitfin Service to manage the relationships with your
              consumers or service users.
            </p>
            <p>
              Device: any internet connected device such as a phone, tablet,
              computer or any other device that can be used to visit Biflare and
              use the services
            </p>
            <p>
              IP address: Every device connected to the Internet is assigned a
              number known as an Internet protocol (IP) address. These numbers
              are usually assigned in geographic blocks. An IP address can often
              be used to identify the location from which device is connecting
              to the Internet.
            </p>
            <p>
              Personal Data: any information that directly, indirectly, or in
              connection with other information - including a personal
              identification number - allows for the identification or
              identifiability of a natural person.
            </p>
            <p>
              Service: refers to the service provided by Bitfin as described in
              the relative terms (if available) and on this platform.
            </p>
            <p>
              Third-party service: refers to advertisers, contest sponsors,
              promotional and marketing partners, and others who provide our
              content or whose products or services we think may interest you.
            </p>
            <p>
              Website: Bitfin's site, which can be accessed via this URL:
              www.Bitfin.live
            </p>
            <p>
              You: a person or entity that is registered with Bitfin to use the
              Services.
            </p>
          </div>

          <div className=" font-Open-Sans font-normal text-sm text-[#FFFFFFCC] mt-[1em] space-y-3">
            <p className=" font-Lato text-lg md:text-2xl font-medium text-white">
              Restrictions
            </p>
            <p>You agree not to, and you will not permit others to:</p>
            <p>
              License, sell, rent, lease, assign, distribute, transmit, host,
              outsource, disclose or otherwise commercially exploit the service
              or make the platform available to any third party.
            </p>
            <p>
              Modify, make derivative works of, disassemble, decrypt, reverse
              compile or reverse engineer any part of the service.
            </p>
            <p>
              Remove, alter or obscure any proprietary notice (including any
              notice of copyright or trademark) of or its affiliates, partners,
              suppliers or the licensors of the service.
            </p>
          </div>

          <div className=" font-Open-Sans font-normal text-sm text-[#FFFFFFCC] mt-[1em] space-y-3">
            <p className=" font-Lato text-lg md:text-2xl font-medium text-white">
              Your Suggestions
            </p>
            <p>
              Any feedback, comments, ideas, improvements or suggestions
              (collectively, "Suggestions") provided by you to us with respect
              to the service shall remain the sole and exclusive property of us.
              We shall be free to use, copy, modify, publish, or redistribute
              the Suggestions for any purpose and in any way without any credit
              or any compensation to you.
            </p>
          </div>
          <div className=" font-Open-Sans font-normal text-sm text-[#FFFFFFCC] mt-[1em] space-y-3">
            <p className=" font-Lato text-lg md:text-2xl font-medium text-white">
              Your Consent
            </p>
            <p>
              We've updated our Terms & Conditions to provide you with complete
              transparency into what is being set when you visit our site and
              how it's being used. By using our service, registering an account,
              or making a purchase, you hereby consent to our Terms &
              Conditions.
            </p>
          </div>
          <div className=" font-Open-Sans font-normal text-sm text-[#FFFFFFCC] mt-[1em] space-y-3">
            <p className=" font-Lato text-lg md:text-2xl font-medium text-white">
              Link To Other Sites
            </p>
            <p>
              Our service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Terms & Conditions of every site You visit. We have no
              control over and assume no responsibility for the content, Terms &
              Conditions or practices of any third party sites or services
            </p>
            <p>
              We use "Cookies" to identify the areas of our website that you
              have visited. A Cookie is a small piece of data stored on your
              computer or mobile device by your web browser. We use Cookies to
              enhance the performance and functionality of our service but are
              non-essential to their use. However, without these cookies,
              certain functionality like videos may become unavailable or you
              would be required to enter your login details every time you visit
              our platform as we would not be able to remember that you had
              logged in previously. Most web browsers can be set to disable the
              use of Cookies. However, if you disable Cookies, you may not be
              able to access functionality on our website correctly or at all.
              We never place Personally Identifiable Information in Cookies.
              Changes To Our Terms & Conditions
            </p>
            <p>
              You acknowledge and agree that we may stop (permanently or
              temporarily) providing the Service (or any features within the
              Service) to you or to users generally at our sole discretion,
              without prior notice to you. You may stop using the Service at any
              time. You do not need to specifically inform us when you stop
              using the Service. You acknowledge and agree that if we disable
              access to your account, you may be prevented from accessing the
              Service, your account details or any files or other materials
              which is contained in your account. If we decide to change our
              Terms & Conditions, we will post those changes on this page,
              and/or update the Terms & Conditions modification date below.
              Modifications to Our service We reserve the right to modify,
              suspend or discontinue, temporarily or permanently, the service or
              any service to which it connects, with or without notice and
              without liability to you.
            </p>
          </div>
          <div className=" font-Open-Sans font-normal text-sm text-[#FFFFFFCC] mt-[1em] space-y-3">
            <p className=" font-Lato text-lg md:text-2xl font-medium text-white">
              Update To Our Services
            </p>
            <p>
              We may from time to time provide enhancements or improvements to
              the features/functionality of the service, which may include
              patches, bug fixes, updates, upgrades and other modifications
              ("Updates"). Updates may modify or delete certain features and/or
              functionalities of the service.You agree that we have no
              obligation to (i) provide any Updates, or (ii) continue to provide
              or enable any particular features and/or functionalities of the
              service to you. You further agree that all Updates will be (i)
              deemed to constitute an integral part of the service, and (ii)
              subject to the terms and conditions of this Agreement.
            </p>
          </div>
          <div className=" font-Open-Sans font-normal text-sm text-[#FFFFFFCC] mt-[1em] space-y-3">
            <p className=" font-Lato text-lg md:text-2xl font-medium text-white">
              Third Party Services
            </p>
            <p>
              We may display, include or make available third-party content
              (including data, information, applications and other products
              services) or provide links to third-party websites or services
              ("Third Party Services"). You acknowledge and agree that we shall
              not be responsible for any Third-Party Services, including
              including their accuracy, completeness, timeliness, validity,
              copyright compliance, legality, decency, quality or any other
              aspect thereof. We do not assume and shall not have any liability
              or responsibility to you or any other person or entity for any
              Third Party Services. Third-Party Services and links thereto are
              provided solely as a convenience to you and you access and use
              them entirely at your own risk and subject to such third parties'
              terms and conditions. Term and Termination This Agreement shall
              remain in effect until terminated by you or us. We may, in its
              sole discretion, at any time and for any or no reason, suspend or
              terminate this Agreement with or without prior notice. This
              Agreement will terminate immediately, without prior notice from
              us, in the event that you fail to comply with any provision of
              this Agreement. You may also terminate this Agreement by deleting
              the service and all copies thereof from your computer. Upon
              termination of this Agreement, you shall cease all use of the
              service and delete all copies of the service from your computer.
              Termination of this Agreement will not limit any of our rights or
              remedies at law or in equity in case of breach by you (during the
              term of this Agreement) of any of your obligations under the
              present Agreement. No Warranties The service is provided to you
              "AS IS" and "AS AVAILABLE" and with all faults and defects without
              warranty of any kind. To the maximum extent permitted under
              applicable law, we, on our own behalf and on behalf of our
              affiliates and our respective licensors and service providers,
              expressly disclaims all warranties, whether express, implied,
              statutory or otherwise, with respect to the service, including all
              implied warranties of merchantability, fitness for a particular
              purpose, title and non-infringement, and warranties that may arise
              out of course of dealing, course of performance, usage or trade
              practice. Without limitation to the foregoing, we provide no
              warranty or undertaking, and makes no representation of any kind
              that the service will meet Without limiting the foregoing, neither
              us nor any provider makes any representation or warranty of any
              kind, express or implied: (i) as to the operation or availability
              of the service, or the information, content, and materials or
              products included thereon; (ii) that the service will be
              uninterrupted or error-free; (iii) as to the accuracy,
              reliability, or currency of any information or content provided
              through the service; or (iv) that the service, its servers, the
              content, or e mails sent from or on behalf of us are free of
              viruses, scripts, trojan horses, worms, malware, timebombs or
              other harmful components. Some jurisdictions do not allow the
              exclusion of or limitations on implied warranties or the
              limitations on the applicable statutory rights of a consumer, so
              some or all of the above exclusions and limitations may not apply
              to you. Limitation of Liability Notwithstanding any damages that
              you might incur, the entire liability of us and any of our
              suppliers under any provision of this Agreement and your exclusive
              remedy for all of the foregoing shall be limited to the amount
              actually paid by you for the service. To the maximum extent
              permitted by applicable law, in no event shall we or our suppliers
              be liable for any special, incidental, indirect, or consequential
              damages whatsoever (including, but not limited to, damages for
              loss of profits, for loss of data or other information, for
              business interruption, for personal injury, for loss of privacy
              arising out of or in any way related to the use of or inability to
              use the service, third-party software and/or third-party hardware
              used with the service, or otherwise in connection with any
              provision of this Agreement).
            </p>
          </div>
        </div>
        <AdOne />
        <Footer />
      </motion.div>
    </>
  );
};

export default Legal;
