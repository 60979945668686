import safe from "../images/safe.svg";
import trust from "../images/trust.svg";
import insure from "../images/insure.svg";
import secure from "../images/secure.svg";

const About = () => {
  return (
    <>
      <div className=" md:px-16 px-3 my-[6em] text-white flex flex-col justify-center items-center">
        <p className=" text-center font-Lato font-medium text-2xl md:text-5xl">
          Your most trusted Brokerage platform
        </p>
        <p className=" text-center font-Open-Sans font-normal text-sm md:text-base md:mt-2">
          Here are a few reasons why you should choose Bitfin
        </p>
        <div className=" flex flex-col md:flex-row justify-center md:justify-between w-full items-center space-y-7 md:space-y-0 md:items-start mt-8">
          <div className=" flex flex-col justify-center items-center">
            <img src={safe} className="" alt="" />
            <p className=" text-lg font-semibold font-Open-Sans text-center">
              Secure Asset Storage
            </p>
            <p className="text-center font-Open-Sans text-sm font-normal leading-5 w-[220px]">
              Your assets are protected by our military-grade encryption and
              storage systems, so you can rest assured that they are always
              safe.
            </p>
          </div>
          <div className=" flex flex-col justify-center items-center">
            <img src={trust} className="" alt="" />
            <p className=" text-lg font-semibold font-Open-Sans text-center">
              Trusted platform
            </p>
            <p className="text-center font-Open-Sans text-sm font-normal leading-5 w-[220px]">
              We have a layered security approach that includes prevention,
              detection, and response measures, so we can protect our systems
              from all types of cyber attacks.
            </p>
          </div>
          <div className=" flex flex-col justify-center items-center">
            <img src={insure} className="" alt="" />
            <p className=" text-lg font-semibold font-Open-Sans text-center">
              Insured Assests
            </p>
            <p className="text-center font-Open-Sans text-sm font-normal leading-5 w-[220px]">
              Invest with confidence. Your assets are insured against fraud,
              theft, and other unforeseen circumstances.
            </p>
          </div>
          <div className=" flex flex-col justify-center items-center">
            <img src={secure} className="" alt="" />
            <p className=" text-lg font-semibold font-Open-Sans text-center">
              Account Security
            </p>
            <p className="text-center font-Open-Sans text-sm font-normal leading-5 w-[220px]">
              We protect your account by implementing the highest security
              standards and security measures to keep your account safe from
              unauthorized access.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
