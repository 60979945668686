import logo from "../images/logo.svg";
import gift from "../images/gift.svg";
import x from "../images/x.svg";
import menu from "../images/menu.svg";
import { Link } from "react-router-dom";
import { useState } from "react";

const Navbar = () => {
  const [banner, setBanner] = useState(true);

  function overlay() {
    //check classlist
    const overlayDiv = document.getElementById("overlay");
    if (overlayDiv.classList.contains("-translate-y-[500px]")) {
      overlayDiv.classList.remove("-translate-y-[500px]");
    } else if (!overlayDiv.classList.contains("-translate-y-[500px]")) {
      overlayDiv.classList.add("-translate-y-[500px]");
    }
  }

  return (
    <>
      <div className=" fixed md:static w-full h-[78px] md:h-auto bg-[#121212] flex flex-row justify-between items-center px-3 z-[9999] md:px-16 py-4 md:py-8 text-white">
        <Link to="/">
          <span className=" flex flex-row items-center space-x-1">
            <img src={logo} className="" alt="" />
            <p className=" font-Bellota text-2xl font-bold">Bitfin</p>
          </span>
        </Link>
        <span className=" hidden lg:flex flex-row items-center space-x-8">
          <Link to="/">
            <p className=" font-Open-Sans text-base font-normal">Home</p>
          </Link>
          <Link to="/Legal">
            <p className=" font-Open-Sans text-base font-normal">Legal</p>
          </Link>
          <Link to="/How-it-works">
            <p className=" font-Open-Sans text-base font-normal">
              How it Works
            </p>
          </Link>
          <Link to="/Contact">
            <p className=" font-Open-Sans text-base font-normal">Contact Us</p>
          </Link>
          <Link to="/About">
            <p className=" font-Open-Sans text-base font-normal">About</p>
          </Link>
        </span>
        <span className=" hidden lg:flex flex-row items-center space-x-8">
          <Link to="/Sign-In">
            <p className=" font-Lato text-base font-normal">Login</p>
          </Link>
          <Link to="/Sign-Up">
            <button className=" px-8 py-3 bg-[#03AAC7] rounded-[30px] text-center font-Lato text-base font-normal">
              Register
            </button>
          </Link>
        </span>
        <div onClick={overlay} className="menu-icon lg:hidden">
          <input className="menu-icon__cheeckbox" type="checkbox" />
          <div className=" lg:hidden">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div
        id="overlay"
        className=" w-full bg-[#1212124D] backdrop-blur-xl p-6 flex justify-center items-center -translate-y-[500px] shadow transition-all duration-700 top-[78px] fixed z-[99999]"
      >
        <div className="w-full flex flex-col justify-center items-center space-y-5">
          <div className=" w-full flex flex-row justify-between">
            <Link
              to="/Sign-In"
              className=" border border-[#EAEBF01A] py-3 w-[48%] text-white text-center font-Lato font-normal rounded-[30px]"
            >
              <button>Login</button>
            </Link>
            <Link
              to="/Sign-Up"
              className=" bg-[#03AAC7] py-3 w-[48%] text-black text-center font-Lato font-normal rounded-[30px]"
            >
              <button>Register</button>
            </Link>
          </div>
          <Link to="/">
            <p className=" font-normal cursor-pointer text-sm text-white font-Open-Sans">
              Home
            </p>
          </Link>
          <Link to="/Legal">
            <p className=" font-normal cursor-pointer text-sm text-white font-Open-Sans">
              Legal
            </p>
          </Link>
          <Link to="/How-it-works">
            <p className=" font-normal cursor-pointer text-sm text-white font-Open-Sans">
              How it work
            </p>
          </Link>
          <Link to="/Contact">
            <p className=" font-normal cursor-pointer text-sm text-white font-Open-Sans">
              Contact us
            </p>
          </Link>
          <Link to="/About">
            <p className=" font-normal cursor-pointer text-sm text-white font-Open-Sans">
              About
            </p>
          </Link>
        </div>
      </div>
      {banner && (
        <div className=" text-center bg-[#03AAC7] py-3 z-[99] text-white hidden md:flex justify-center items-center relative">
          <span className=" flex flex-row items-center justify-center">
            <img src={gift} className="" alt="" />
            <p className="ml-2 mr-6 text-base font-Open-Sans font-normal mt-1">
              Register now - Get up to 10% bonus on first deposit (for verified
              users)
            </p>
            <Link to="/Sign-Up">
              <button className=" text-black bg-white text-base font-normal font-Lato px-6 py-2 rounded-[30px]">
                Sign up now
              </button>
            </Link>
          </span>
          <img
            src={x}
            onClick={() => {
              setBanner(false);
            }}
            className=" cursor-pointer absolute right-10"
            alt=""
          />
        </div>
      )}
    </>
  );
};

export default Navbar;
