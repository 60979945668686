import AdOne from "../comps/adone";
import Footer from "../comps/footer";
import Navbar from "../comps/navbar";
import midstar from "../images/midstar.svg";
import planet from "../images/planet.svg";
import mob from "../images/planet.png";
import { motion } from "framer-motion";

const AboutPage = () => {
  return (
    <>
      <Navbar />
      <motion.div
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -100, opacity: 0 }}
        transition={{ type: "spring", stiffness: 80, duration: 0.2 }}
      >
        <img src={midstar} className=" -z-10 centered top-[0vh]" alt="" />
        <div className=" md:px-0 flex flex-col justify-center items-center space-y-7 text-white">
          <span className=" bg-[#03AAC7] text-black py-1 px-4 font-Open-Sans text-sm md:text-base font-normal rounded-[40px] mt-[3em]">
            About us
          </span>
          <p className=" font-Lato text-[32px] md:text-5xl text-white font-semibold leading-9 text-center md:px-[20%]">
            About Bitfin
          </p>
          <p className=" text-lg font-Open-Sans font-normal text-center leading-6 md:px-[15%]">
            We are a leading AI trading company that uses artificial
            intelligence to help traders make more informed decisions, automate
            their trading, and manage risk. Our proprietary algorithms are able
            to scan the market for trading opportunities, execute trades, and
            manage risk in real time.
          </p>
          <button className=" bg-[#03AAC7] px-8 py-3 rounded-[30px] font-Lato text-lg md:text-xl font-normal glower">
            Get started
          </button>
        </div>
        <div className="  px-3 md:px-16 z-10 pt-8 bg-[#121212] -translate-y-14 text-white flex flex-col md:flex-row justify-between">
          <div className=" w-full md:w-[48%]">
            <p className="text-2xl md:text-4xl font-Lato font-medium">
              Our mission
            </p>
            <div className=" mt-[2em] space-y-8">
              <p className="font-Open-Sans font-normal text-sm text-[#FFFFFFCC]">
                We believe that everyone should have the opportunity to use AI
                trading to achieve their financial goals. We make AI trading
                accessible, efficient, and profitable for everyone by providing
                a suite of services that are easy to use, regardless of
                experience level.
              </p>
              <p className="font-Open-Sans font-normal text-sm text-[#FFFFFFCC]">
                Our services include algorithmic trading, managed accounts. We
                are committed to providing our clients with the best possible
                trading experience, and our team of experts is available 24/7 to
                answer questions and provide support. We are also constantly
                innovating and developing new ways to use AI trading to help
                everyone achieve their financial goals.
              </p>
            </div>
            <img src={mob} className=" block md:hidden my-8" alt="" />
            <p className=" mt-[1em] text-2xl md:text-4xl font-Lato font-medium">
              Our values
            </p>
            <div className="font-Open-Sans font-normal text-sm space-y-3 text-[#FFFFFFCC] mt-2">
              <span className="flex flex-row">
                <p className="">We believe in the following values:</p>
              </span>
              <span className="flex flex-row space-x-1">
                <p>1.</p>
                <p className="">
                  Innovation: We are constantly innovating and developing new
                  ways to use AI to help traders.
                </p>
              </span>
              <span className="flex flex-row space-x-1">
                <p>2.</p>
                <p className="">
                  Transparency: We are committed to transparency and providing
                  our clients with the information they need to make informed
                  decisions.
                </p>
              </span>
              <span className="flex flex-row space-x-1">
                <p>3.</p>
                <p className="">
                  Client-centricity: We are committed to putting our clients
                  first and providing them with the best possible trading
                  experience.
                </p>
              </span>
              <span className="flex flex-row space-x-1">
                <p>4.</p>
                <p className="">
                  Excellence: We strive for excellence in everything we do and
                  are committed to providing our clients with the highest
                  quality services.
                </p>
              </span>
            </div>
          </div>
          <div className=" w-full hidden md:block md:w-[48%] ">
            <img src={planet} className=" mt-[1em]" alt="" />
          </div>
        </div>
        <AdOne />
        <Footer />
      </motion.div>
    </>
  );
};

export default AboutPage;
