import { Link } from "react-router-dom";
import vector from "../images/Vector.svg";
import arrowdown from "../images/arrow-down.svg";
import telegram from "../images/telegram.svg";

const Footer = () => {
  const dropDown = () => {
    const faqs = document.querySelectorAll(".faq");

    // Add a click event listener to each FAQ element
    faqs.forEach((faq) => {
      faq.addEventListener("click", () => {
        // Toggle the "active" class only for the clicked FAQ
        faq.classList.toggle("active");
      });
    });
  };

  dropDown();

  return (
    <>
      <div className=" bg-[#002F37] px-3 md:px-16 pt-10 pb-5 md:mt-[4em] text-[#FFFFFF80]">
        <div className=" flex flex-col md:flex-row md:justify-between items-start md:space-y-0 space-y-6">
          <div onClick={dropDown} className=" faq space-y-3 w-full md:w-auto">
            <span className=" text-white flex md:block flex-row justify-between w-full font-Lato text-base font-medium">
              <p>About Bitfin</p>
              <img src={arrowdown} className=" block md:hidden" alt="" />
            </span>
            <div className=" answ space-y-2">
              <Link to="/About">
                <p className=" font-Open-Sans text-sm font-normal">About Us</p>
              </Link>
              <Link to="/Legal">
                <p className=" font-Open-Sans text-sm font-normal">
                  Terms of Use
                </p>
              </Link>
              <Link to="/Legal">
                <p className=" font-Open-Sans text-sm font-normal">
                  Privacy Policy
                </p>
              </Link>
              <Link to="/Legal">
                <p className=" font-Open-Sans text-sm font-normal">Legal</p>
              </Link>
            </div>
          </div>
          <div onClick={dropDown} className=" faq space-y-3 w-full md:w-auto">
            <span className=" text-white flex md:block flex-row justify-between w-full font-Lato text-base font-medium">
              <p>Products</p>
              <img src={arrowdown} className=" block md:hidden" alt="" />
            </span>
            <div className=" answ space-y-2">
              <Link to="/Sign-In">
                <p className=" font-Open-Sans text-sm font-normal">Convert</p>
              </Link>
              <Link to="/Sign-In">
                <p className=" font-Open-Sans text-sm font-normal">
                  Trading bot
                </p>
              </Link>
            </div>
          </div>
          <div onClick={dropDown} className=" faq space-y-3 w-full md:w-auto">
            <span className=" text-white flex md:block flex-row justify-between w-full font-Lato text-base font-medium">
              <p>Support</p>
              <img src={arrowdown} className=" block md:hidden" alt="" />
            </span>
            <div className=" answ space-y-2">
              <Link to="/Contact Us"></Link>
              <p className=" font-Open-Sans text-sm font-normal">Help Center</p>
              <Link to="/"></Link>
              <p className=" font-Open-Sans text-sm font-normal">
                Announcement
              </p>
              <Link to="/"></Link>
              <p className=" font-Open-Sans text-sm font-normal">Affiliates</p>
              <Link to="/"></Link>
              <p className=" font-Open-Sans text-sm font-normal">
                API Documentation
              </p>
            </div>
          </div>
          <div onClick={dropDown} className=" faq space-y-3 w-full md:w-auto">
            <span className=" text-white flex md:block flex-row justify-between w-full font-Lato text-base font-medium">
              <p>Trade</p>
              <img src={arrowdown} className=" block md:hidden" alt="" />
            </span>
            <div className=" answ space-y-2">
              <Link to="/Sign-In">
                <p className=" font-Open-Sans text-sm font-normal">BTC/USDT</p>
              </Link>
              <Link to="/Sign-In">
                <p className=" font-Open-Sans text-sm font-normal">ETH/USDT</p>
              </Link>
              <Link to="/Sign-In">
                <p className=" font-Open-Sans text-sm font-normal">XRP/USDT</p>
              </Link>
              <Link to="/Sign-In">
                <p className=" font-Open-Sans text-sm font-normal">BTCUSDT-P</p>
              </Link>
              <Link to="/Sign-In">
                <p className=" font-Open-Sans text-sm font-normal">ETHUSDT-P</p>
              </Link>
              <Link to="/Sign-In">
                <p className=" font-Open-Sans text-sm font-normal">XRPUSDT-P</p>
              </Link>
            </div>
          </div>
        </div>
        <div className=" pt-6 flex justify-between items-center">
          <span className=" flex items-center space-x-3">
            <img src={vector} alt="" />
            <p className="font-Lato text-white font-light text-sm">
              © 2023 Bitfin.
            </p>
          </span>
          <span className=" flex flex-row space-x-3 pr-6">
            <img src={telegram} alt="" />
          </span>
        </div>
      </div>
    </>
  );
};

export default Footer;
