import React, { useEffect, useRef } from 'react';

const GbpComp = () => {
  const widgetContainerRef = useRef();

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
    script.async = true;
    script.innerHTML = `
      {
        "symbol": "OANDA:AUDUSD",
        "width": "100%",
        "height": "100%",
        "locale": "en",
        "dateRange": "12M",
        "colorTheme": "dark",
        "isTransparent": true,
        "autosize": true,
        "largeChartUrl": "",
        "chartOnly": false
      }
    `;

    widgetContainerRef.current.appendChild(script);

    return () => {
      // Cleanup when the component unmounts
      widgetContainerRef.current.removeChild(script);
    };
  }, []);

  return (
    <div className="tradingview-widget-container" ref={widgetContainerRef}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
};

export default GbpComp;
