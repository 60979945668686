import arrowdown from "../images/arrow-down.svg";

const Faq = () => {
  const dropDown = () => {
    const faqs = document.querySelectorAll(".faq");

    // Add a click event listener to each FAQ element
    faqs.forEach((faq) => {
      faq.addEventListener("click", () => {
        // Toggle the "active" class only for the clicked FAQ
        faq.classList.toggle("active");
      });
    });
  };

  dropDown();

  return (
    <>
      <div className=" px-3 md:px-16 my-[6em] text-white flex flex-col justify-center items-center">
        <p className=" text-center font-Lato text-2xl md:text-4xl font-medium">
          Frequently asked questions?
        </p>
        <div className=" flex flex-col w-full mt-16">
          <div
            onClick={dropDown}
            className=" border-y py-6 faq border-[#EAEBF033] "
          >
            <div className=" flex flex-row justify-between w-full items-center">
              <p className=" font-Open-Sans text-base md:text-xl font-normal">
                How do I create an account on Bitfin?
              </p>
              <img src={arrowdown} className="" alt="" />
            </div>
            <div className=" answer">
              <p className=" font-Open-Sans font-normal text-base mt-5 transition-all duration-500 text-[#FFFFFFB2]">
                Bitfin strives to be as user-friendly as possible. To create an
                account on Bitfin, simply visit the homepage and locate the
                navigation bar. Look for the "Register" button and click on it.
                Fill in the necessary information, including your email,
                password, first name, last name. Make sure to carefully read and
                agree to the terms and conditions by selecting the checkboxes
                provided. Afterward, proceed to verify your account. You will be
                able to log in to your newly created account and begin earning.
              </p>
            </div>
          </div>

          <div
            onClick={dropDown}
            className=" border-b py-6 faq border-[#EAEBF033] "
          >
            <div className=" flex flex-row justify-between w-full items-center">
              <p className=" font-Open-Sans text-base md:text-xl font-normal">
                How can I contact your customer support?
              </p>
              <img src={arrowdown} className="" alt="" />
            </div>
            <div className=" answer">
              <p className=" font-Open-Sans font-normal text-base mt-5 transition-all duration-500 text-[#FFFFFFB2]">
                We aim and promise to be a user-centric crypto trading
                investment platform, which is why we have made different
                channels for our users to contact us. If you have any queries,
                you can contact our customer support team available 24/7 through
                support@Bitfin.live, telegram, or by clicking the chat bubble on
                the bottom right corner.
              </p>
            </div>
          </div>

          <div
            onClick={dropDown}
            className=" border-b py-6 faq border-[#EAEBF033] "
          >
            <div className=" flex flex-row justify-between w-full items-center">
              <p className=" font-Open-Sans text-base md:text-xl font-normal">
                Is Bitfin a safe crypto investment platform?
              </p>
              <img src={arrowdown} className="" alt="" />
            </div>
            <div className=" answer">
              <p className=" font-Open-Sans font-normal text-base mt-5 transition-all duration-500 text-[#FFFFFFB2]">
                Yes, we put user security at the helm of our offering as we have
                partnered with big names in the industry like Fireblocks, Jumio,
                Coincover, and Chainalaysis. This allows us to fortify our
                platform against fraudulent activities and help us adhere to the
                best asset custody, KYC solutions, insurance, and compliance
                practices.
              </p>
            </div>
          </div>

          <div
            onClick={dropDown}
            className=" border-b py-6 faq border-[#EAEBF033] "
          >
            <div className=" flex flex-row justify-between w-full items-center">
              <p className=" font-Open-Sans text-base md:text-xl font-normal">
                How do I Buy Bitcoin?
              </p>
              <img src={arrowdown} className="" alt="" />
            </div>
            <div className=" answer">
              <p className=" font-Open-Sans font-normal text-base mt-5 transition-all duration-500 text-[#FFFFFFB2]">
                Bitfin offers its users access to Bitcoin and other major
                cryptocurrencies in the market and other markets too. To buy,
                all you need to do is to deposit funds to your account. You can
                start with as little as 100 dollars and buy as much as you want.
              </p>
            </div>
          </div>

          <div
            onClick={dropDown}
            className=" border-b py-6 faq border-[#EAEBF033] "
          >
            <div className=" flex flex-row justify-between w-full items-center">
              <p className=" font-Open-Sans text-base md:text-xl font-normal">
                How do I deposit/withdraw cryptocurrency?
              </p>
              <img src={arrowdown} className="" alt="" />
            </div>
            <div className=" answer">
              <p className=" font-Open-Sans font-normal text-base mt-5 transition-all duration-500 text-[#FFFFFFB2]">
                To deposit and withdraw cryptocurrency, you will need an
                existing crypto wallet. Depositing funds to your Bitfin account
                or withdrawing funds from your Bitfin account should not take
                more than 5 minutes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
