import stars from "../images/stars.svg";
import star from "../images/star.svg";
import heroImg from "../images/heropic.png";
import mobHeroImg from "../images/MobHeroImg.png";
import midstar from "../images/midstar.svg";
import liner from "../images/Liner.svg";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <img src={star} className=" absolute -z-10 left-0 top-[-5vh]" alt="" />
      <img src={midstar} className=" centered -z-10 top-[15vh]" alt="" />
      <img src={stars} className=" absolute -z-10 right-0 top-[-5vh]" alt="" />
      <div className=" md:px-0 flex flex-col justify-center items-center space-y-5 text-white">
        <motion.span
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 50,
            duration: 0.3,
            delay: 0.5,
          }}
          className=" bg-[#03AAC7] mt-[8em] text-black py-1 px-4 font-Open-Sans text-sm md:text-base font-normal rounded-[40px] md:mt-[3em]"
        >
          Bitfin offers
        </motion.span>
        <motion.p
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 50,
            duration: 0.3,
            delay: 0.8,
          }}
          className=" font-Lato text-[32px] md:text-5xl text-white font-semibold leading-9 z-[999] text-center md:px-[20%]"
        >
          The Most Efficient AI Powered Trading and Investment Platform
        </motion.p>
        <motion.p
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 50,
            duration: 0.3,
            delay: 1.1,
          }}
          className=" text-lg font-Open-Sans font-normal text-center leading-6 px-3 z-[999] md:px-[15%]"
        >
          Professional trading system that automatically scans, analyzes, and
          develops strategies and trades by statistical arbitrage in
          cryptocurrency, Stocks and Forex markets
        </motion.p>
        <motion.button
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 50,
            duration: 0.3,
            delay: 1.4,
          }}
          className=" bg-[#03AAC7] z-[99] px-8 py-3 rounded-[30px] font-Lato text-lg md:text-xl font-normal glow"
        >
          <Link to="/Sign-Up">Get started</Link>
        </motion.button>
      </div>
      <motion.div className=" px-3 md:px-16 mt-[4em] md:mt-[8em] text-white flex justify-center items-center">
        <motion.div className=" bg-[#1e1e1e] rounded-[15px]">
          <img src={heroImg} className="z-20 hidden md:block" alt="" />
          <img src={mobHeroImg} className="z-20 md:hidden block" alt="" />
        </motion.div>
      </motion.div>
      <div className=" md:px-16 px-3 flex my-[6em] flex-col md:flex-row justify-center md:justify-between items-center text-white space-y-6 md:space-y-0">
        <span className=" flex flex-col text-center md:text-left w-full md:w-1/3">
          <p className=" font-Lato font-medium text-[1.95rem]">$10 million</p>
          <p className=" text-[1rem] font-Open-Sans font-medium">
            Quarterly trading volume
          </p>
        </span>
        <img src={liner} className="" alt="" />
        <span className=" flex flex-col text-center md:text-center border-[#03AAC733] md:border-x w-full md:w-1/3">
          <p className=" font-Lato font-medium text-[1.95rem]">2 million</p>
          <p className=" text-[1rem] font-Open-Sans font-medium">
            Registered users
          </p>
        </span>
        <img src={liner} className="" alt="" />
        <span className=" flex flex-col text-center md:text-right w-full md:w-1/3">
          <p className=" font-Lato font-medium text-[1.95rem]">200 +</p>
          <p className=" text-[1rem] font-Open-Sans font-medium">
            countries covered
          </p>
        </span>
      </div>
    </>
  );
};

export default Hero;
