import overviewIcon from "../images/ova.svg";
import cryptoIcon from "../images/crypto-icon.svg";
import forexIcon from "../images/forex-icon.svg";
import stockIcon from "../images/stock-icon.svg";
import settingIcon from "../images/setting.png";
import logo from "../images/logo.svg";
import box from "../images/box.svg";
import reciept from "../images/receipt.svg";
import trades from "../images/trades.svg";
import logout from "../images/logout.svg";
import { useContext, useState } from "react";
import { PageContext } from "../contexts/pageContext";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const [page, setPage] = useContext(PageContext);
  const [activePage, setActivePage] = useState("Overview"); // Initially set to the default active page

  const Navigate = useNavigate();

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
  const auth = getAuth();

  const pages = [
    { name: "Overview", icon: overviewIcon },
    { name: "Cryptocurrency", icon: cryptoIcon },
    { name: "Forex", icon: forexIcon },
    { name: "Stocks", icon: stockIcon },
    { name: "Trades", icon: trades },
    { name: "Packages", icon: box },
    { name: "Transactions", icon: reciept },
    { name: "Settings", icon: settingIcon },
  ];

  const logOut = () => {
    signOut(auth)
      .then(() => {
        Navigate("/Sign-In");
      })
      .catch((error) => {
        console.log("An error happened");
      });
  };

  return (
    <>
      <span className=" absolute top-5 right-4 flex md:hidden  items-center">
        <img src={logo} alt="" />
        <p className=" font-Bellota text-2xl font-bold text-white">Bitfin</p>
      </span>
      <div className="fixed top-[80px] left-0 w-[80%] md:w-[100%] h-[100vh] md:border-r pt-6 border-[#EAEBF01A]">
        {pages.map((pageItem) => (
          <button
            key={pageItem.name}
            onClick={() => {
              setActivePage(pageItem.name);
              setPage(pageItem.name);
            }}
            className={`w-full pl-8 flex py-[10px] space-x-3 items-center ${
              activePage === pageItem.name
                ? "bg-[#04181B] border-l-[3px] text-[#03AAC7] border-[#03AAC7]"
                : ""
            }`}
          >
            <img src={pageItem.icon} className="w-5 h-5" alt="" />
            <p className="font-Nunito block md:hidden lg:block font-medium text-sm text-[#666666]">
              {pageItem.name}
            </p>
          </button>
        ))}
        <div className="absolute bottom-24">
          <button
            onClick={logOut}
            className="w-full pl-8 flex py-[10px] space-x-3 items-center"
          >
            <img src={logout} className="w-5 h-5" alt="" />
            <p className="font-Nunito block md:hidden lg:block font-medium text-sm text-[#666666]">
              Logout
            </p>
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
