import profile from "../images/profile.svg";
import wallet from "../images/wallet.svg";
import bot from "../images/bot.svg";
import line from "../images/Line.svg";

const Howitworks = () => {
  return (
    <>
      <div className=" px-3 md:px-16 my-[6em] text-white flex flex-col justify-center items-center">
        <p className=" font-Lato font-medium text-2xl md:text-5xl text-center">
          We make crypto trading easy
        </p>
        <p className=" text-center font-Open-Sans text-sm md:text-base font-normal mt-2">
          Join Bitfin and begin trading in just 3 easy steps.
        </p>
        <div className=" flex flex-col md:flex-row justify-center md:space-y-0 space-y-8 md:justify-between w-full items-center md:items-start mt-8">
          <div className=" flex flex-col justify-center items-center">
            <img src={profile} className="" alt="" />
            <p className=" text-lg font-semibold font-Lato text-center mt-6">
              Create Account
            </p>
            <p className="text-center font-Open-Sans text-sm font-normal leading-5 w-[250px] mt-2 text-[#FFFFFFCC]">
              Your assets are protected by our military-grade encryption and
              storage systems, so you can rest assured that they are always
              safe.
            </p>
          </div>
          <div className=" flex flex-col justify-center items-center">
            <img src={wallet} className="" alt="" />
            <p className=" text-lg font-semibold font-Lato text-center mt-6">
              Invest
            </p>
            <p className="text-center font-Open-Sans text-sm font-normal leading-5 w-[250px] mt-2 text-[#FFFFFFCC]">
              We have a layered security approach that includes prevention,
              detection, and response measures, so we can protect our systems
              from all types of cyber attacks.
            </p>
          </div>
          <div className=" flex flex-col justify-center items-center">
            <img src={bot} className="" alt="" />
            <p className=" text-lg font-semibold font-Lato text-center mt-6">
              Start Earning
            </p>
            <p className="text-center font-Open-Sans text-sm font-normal leading-5 w-[250px] mt-2 text-[#FFFFFFCC]">
              We automatically trade crypto, forex and stocks with up to 100x
              leverage for you.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Howitworks;
