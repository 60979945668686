import Sidebar from '../comps/sidebar';
import Topbar from '../comps/topbar';
import menu from '../images/menu.svg';
import { PageContext, dogeData, ethData, qrData, tronData, usdtData } from '../contexts/pageContext';
import { useContext, useEffect } from 'react';
import Overview from './overview';
import Packages from '../comps/packages';
import TransactionHistory from '../comps/transactionHistory';
import CryptoDash from '../comps/cryptoDash';
import Trades from '../comps/trades';
import StockDash from '../comps/stockDash';
import { motion } from 'framer-motion';
import Settings from '../comps/setting';
import ForexDash from '../comps/forexDash';
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import close from '../images/close.svg';
import btcqr from '../images/btcqr.svg';
import ethqr from '../images/ethqr.svg';
import dogeqr from '../images/dogeqr.svg';
import usdtqr from '../images/usdtqr.svg';
import trxqr from '../images/trxqr.svg';

const Dashboard = () => {
    const [page, setPage] = useContext(PageContext);
    const Navigate = useNavigate();
    const [codeImage, setCodeImage] = useContext(qrData);
    const [ethImg, setEthImg] = useContext(ethData);
    const [dogeImg, setDogeImg] = useContext(dogeData);
    const [usdtImg, setUsdtImg] = useContext(usdtData);
    const [tronImg, setTronImg] = useContext(tronData);

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const auth = getAuth();

    //overlay function
    useEffect(() => {
        const menuBtn = document.getElementById('menu');
        const overlay = document.getElementById('overlay');

        const handleMenuClick = () => {
            const isClose = overlay.classList.contains('-translate-x-[100%]');
            if (isClose) {
                overlay.classList.remove('-translate-x-[100%]');
            } else {
                overlay.classList.add('-translate-x-[100%]');
            }
        };

        menuBtn.addEventListener('click', handleMenuClick);

        // Clean up the event listener when the component unmounts
        return () => {
            menuBtn.removeEventListener('click', handleMenuClick);
        };
    }, []);

    //page change
    useEffect(()=>{
        const overview = document.getElementById('overview');
        const crypt = document.getElementById('crypto');
        const forex = document.getElementById('forex');
        const stock = document.getElementById('stock');
        const history = document.getElementById('history');
        const trades = document.getElementById('trades');
        const packages = document.getElementById('packages');
        const setting = document.getElementById('setting');

        if(page === 'Overview'){
            overview.style.display = 'block';
            crypt.style.display = 'none';
            forex.style.display = 'none';
            stock.style.display = 'none';
            history.style.display = 'none';
            trades.style.display = "none";
            setting.style.display = 'none';
            packages.style.display = 'none';
        }
        else if(page === 'Cryptocurrency'){
            overview.style.display = 'none';
            crypt.style.display = 'block';
            forex.style.display = 'none';
            stock.style.display = 'none';
            history.style.display = 'none';
            trades.style.display = "none";
            setting.style.display = 'none';
            packages.style.display = 'none';
        }
        else if(page === 'Trades'){
            overview.style.display = 'none';
            crypt.style.display = 'none';
            forex.style.display = 'none';
            stock.style.display = 'none';
            history.style.display = 'none';
            trades.style.display = "block";
            setting.style.display = 'none';
            packages.style.display = 'none';
        }
        else if(page === 'Forex'){
            overview.style.display = 'none';
            crypt.style.display = 'none';
            forex.style.display = 'block';
            stock.style.display = 'none';
            history.style.display = 'none';
            trades.style.display = "none";
            setting.style.display = 'none';
            packages.style.display = 'none';
        }
        else if(page === 'Stocks'){
            overview.style.display = 'none';
            crypt.style.display = 'none';
            forex.style.display = 'none';
            stock.style.display = 'block';
            history.style.display = 'none';
            trades.style.display = "none";
            setting.style.display = 'none';
            packages.style.display = 'none';
        }
        else if(page === 'Transactions'){
            overview.style.display = 'none';
            crypt.style.display = 'none';
            forex.style.display = 'none';
            stock.style.display = 'none';
            history.style.display = 'block';
            trades.style.display = "none";
            setting.style.display = 'none';
            packages.style.display = 'none';
        }
        else if(page === 'Settings'){
            overview.style.display = 'none';
            crypt.style.display = 'none';
            forex.style.display = 'none';
            stock.style.display = 'none';
            history.style.display = 'none';
            trades.style.display = "none";
            setting.style.display = 'block';
            packages.style.display = 'none';
        }
        else if(page === 'Packages'){
            overview.style.display = 'none';
            crypt.style.display = 'none';
            forex.style.display = 'none';
            stock.style.display = 'none';
            history.style.display = 'none';
            trades.style.display = "none";
            setting.style.display = 'none';
            packages.style.display = 'block';
        };
    })

    useEffect(() => {
        const overlay = document.getElementById('overlay');
        overlay.classList.add('-translate-x-[100%]');
    }, [page]);

    useEffect(()=>{
        onAuthStateChanged(auth, (user) =>{
            if (user){
              const uid = user.uid;
            }else{
                Navigate('/Sign-In')
            }
        });
    });

    return (
        <motion.div
        initial={{x:100, opacity:0}}
        animate={{x:0, opacity:1}}
        exit={{x:-100, opacity:0}}
        transition={{type:'spring', stiffness:80, duration:0.2}}
        >
          <div id="overlay" className='-translate-x-[100%] md:-translate-x-0 transition-all duration-500 w-[80%] md:w-[10%] lg:w-[20%] h-[100vh] py-3 px-3 md:px-5 fixed top-0 left-0 bg-[#1e1e1e] md:bg-transparent z-[999]'>
                <Sidebar />
            </div>
            <div className=' w-[100%]'>

                { codeImage && <div className=' w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[99999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5'>
                    <div className=' bg-[#2b2b2b] w-full md:w-[350px] p-10 h-[350px] relative flex justify-center items-center rounded-[30px]'>
                        <img src={ close } onClick={()=>{setCodeImage(false)}} className=' absolute top-3 right-3' alt="" />
                        <img src={ btcqr } className=' md:border border-[#fff] w-full h-full'  alt="" />
                    </div>
                </div> }
                { ethImg && <div className=' w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[99999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5'>
                    <div className=' bg-[#2b2b2b] w-full md:w-[350px] p-10 h-[350px] relative flex justify-center items-center rounded-[30px]'>
                        <img src={ close } onClick={()=>{setEthImg(false)}} className=' absolute top-3 right-3' alt="" />
                        <img src={ ethqr } className=' md:border border-[#fff] w-full h-full'  alt="" />
                    </div>
                </div> }
                { dogeImg && <div className=' w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[99999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5'>
                    <div className=' bg-[#2b2b2b] w-full md:w-[350px] p-10 h-[350px] relative flex justify-center items-center rounded-[30px]'>
                        <img src={ close } onClick={()=>{setDogeImg(false)}} className=' absolute top-3 right-3' alt="" />
                        <img src={ dogeqr } className=' md:border border-[#fff] w-full h-full'  alt="" />
                    </div>
                </div> }
                { usdtImg && <div className=' w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[99999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5'>
                    <div className=' bg-[#2b2b2b] w-full md:w-[350px] p-10 h-[350px] relative flex justify-center items-center rounded-[30px]'>
                        <img src={ close } onClick={()=>{setUsdtImg(false)}} className=' absolute top-3 right-3' alt="" />
                        <img src={ usdtqr } className=' md:border border-[#fff] w-full h-full'  alt="" />
                    </div>
                </div> }
                { tronImg && <div className=' w-[100%] h-[100vh] bg-[rgba(0,0,0,0.7)] z-[99999] fixed text-white top-0 left-0 flex justify-center items-center md:px-5'>
                    <div className=' bg-[#2b2b2b] w-full md:w-[350px] p-10 h-[350px] relative flex justify-center items-center rounded-[30px]'>
                        <img src={ close } onClick={()=>{setTronImg(false)}} className=' absolute top-3 right-3' alt="" />
                        <img src={ trxqr } className=' md:border border-[#fff] w-full h-full'  alt="" />
                    </div>
                </div> }

                <img src={ menu } id="menu" className=" block md:hidden fixed z-[999] top-6 left-4 w-6 h-6" alt="" />
                <Topbar />
                <div id="overview" className=" md:left-[10%] lg:left-[20%] top-[80px] w-full md:w-[90%] lg:w-[80%] absolute py-6 px-3 md:px-6 ">
                    <p className=" font-Lato text-[32px]  font-semibold text-white -mt-3 mb-3">{ page }</p>
                    <Overview/>
                </div>
                <div id="packages" className=" md:left-[10%] lg:left-[20%] top-[80px] w-full md:w-[90%] lg:w-[80%] absolute py-6 px-3 md:px-6 ">
                    <p className=" font-Lato text-[32px]  font-semibold text-white -mt-3 mb-3">{ page }</p>
                    <Packages/>
                </div>
                <div id="trades" className=" md:left-[10%] lg:left-[20%] top-[80px] w-full md:w-[90%] lg:w-[80%] absolute py-6 -z-30 px-3 md:px-6 ">
                    <p className=" font-Lato text-[32px]  font-semibold text-white -mt-3 mb-3">{ page }</p>
                    <Trades/>
                </div>
                <div id="history" className=" md:left-[10%] lg:left-[20%] top-[80px] w-full md:w-[90%] lg:w-[80%] absolute py-6 -z-30 px-3 md:px-6 ">
                    <p className=" font-Lato text-[32px]  font-semibold text-white -mt-3 mb-3">{ page }</p>
                    <TransactionHistory/>
                </div>
                <div id="crypto" className=" md:left-[10%] lg:left-[20%] top-[80px] w-full md:w-[90%] lg:w-[80%] absolute py-6 px-3 md:px-6 ">
                    <p className=" font-Lato text-[32px]  font-semibold text-white -mt-3 mb-3">{ page }</p>
                    <CryptoDash/>
                </div>
                <div id="stock" className=" md:left-[10%] lg:left-[20%] top-[80px] w-full md:w-[90%] lg:w-[80%] absolute py-6 px-3 md:px-6 ">
                    <p className=" font-Lato text-[32px]  font-semibold text-white -mt-3 mb-3">{ page }</p>
                    <StockDash/>
                </div>
                <div id="forex" className=" md:left-[10%] lg:left-[20%] top-[80px] w-full md:w-[90%] lg:w-[80%] absolute py-6 px-3 md:px-6 ">
                    <p className=" font-Lato text-[32px]  font-semibold text-white -mt-3 mb-3">{ page }</p>
                    <ForexDash/>
                </div>
                <div id="setting" className=" md:left-[10%] lg:left-[20%] top-[80px] w-full md:w-[90%] lg:w-[80%] absolute py-6 px-3 md:px-6 ">
                    <p className=" font-Lato text-[32px]  font-semibold text-white -mt-3 mb-3">{ page }</p>
                    <Settings/>
                </div>
            </div>
        </motion.div>
    );
}

export default Dashboard;
