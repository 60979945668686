import logo from "../images/logo.svg";
import profie from "../images/profie.svg";
import noti from "../images/notification.svg";
import { PageContext, UserInfo } from "../contexts/pageContext";
import { useContext } from "react";

const Topbar = () => {
  const [userData, setUserData] = useContext(UserInfo);
  const [page, setPage] = useContext(PageContext);

  const handleReferClick = (e) => {
    const parent = e.target.parentElement;
    const elements = parent.children;
    const addy = elements[1];

    // Check if the addy element is an input element
    if (addy && addy instanceof HTMLInputElement) {
      addy.select();
      addy.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(addy.value);
    }
  };

  return (
    <>
      <div className=" fixed top-0 left-0 h-[80px] w-full border-b z-20 bg-[#121212] border-[#EAEBF01A] justify-between text-white flex items-center px-8">
        <span className=" hidden md:flex flex-row items-center space-x-1">
          <img src={logo} className="" alt="" />
          <p className=" hidden md:hidden lg:block  font-Bellota text-2xl font-bold">
            Bitfin
          </p>
        </span>
        <div type="text" className=" md:absolute md:left-[10%] lg:left-[20%]">
          <span className=" ml-6 flex flex-row space-x-1 items-center">
            <p className=" font-Nunito font-light text-sm md:text-xl">
              Welcome,
            </p>
            <p className=" font-Nunito-Sans font-normal text-sm md:text-xl">
              {userData.name}
            </p>
          </span>
        </div>
        <div className=" flex items-center space-x-4 md:space-x-6">
          <span className=" hidden lg:flex flex-row font-Nunito text-sm w-full md:w-[320px] items-center font-medium text-[#FFFFFFB2] relative">
            <p className=" font-Nunito text-sm font-medium rounded-l-md mt-2 flex justify-center items-center pl-3 h-[40px] bg-[rgba(78,78,97,0.2)]">
              Referral Code:
            </p>
            <input
              type="text"
              value={"https://bit.ly/45cSoif"}
              readOnly
              className=" h-[40px] mt-2 p-3 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] "
            />
            <button
              onClick={handleReferClick}
              className=" px-3 h-[40px] bg-[#03AAC7] text-white font-Nunito text-sm font-semibold absolute right-0 top-[8px] rounded-r-md"
            >
              Copy
            </button>
          </span>
          <span
            onClick={() => {
              setPage("Settings");
            }}
            className=" w-[32px] h-[32px] md:w-[45px] md:h-[45px] rounded-[50%]"
          >
            <img src={profie} alt="" />
          </span>
          <span className=" w-[32px] h-[32px]  rounded-[50%] flex justify-center border border-[#EAEBF01A] items-center">
            <img src={noti} alt="" />
          </span>
        </div>
      </div>
    </>
  );
};

export default Topbar;
