import { createContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  doc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const PageContext = createContext();
export const UserInfo = createContext();
export const qrData = createContext();
export const ethData = createContext();
export const dogeData = createContext();
export const usdtData = createContext();
export const tronData = createContext();

const PageProvider = (props) => {
  const [page, setPage] = useState("Overview");
  const [codeImage, setCodeImage] = useState(false);
  const [ethImg, setEthImg] = useState(false);
  const [dogeImg, setDogeImg] = useState(false);
  const [usdtImg, setUsdtImg] = useState(false);
  const [tronImg, setTronImg] = useState(false);
  const [userData, setUserData] = useState({
    name: "John Doe",
    mail: "Johndoe@mail.com",
    country: "USA",
    phone: "0987654",
    plan: "Basic",
    verify: "Pending",
    comissionFee: "false",
    CryptoTotalbal: "0",
    CryptoDeposit: "0",
    CryptoProfits: "0",
    ForexTotalbal: "0",
    ForexDeposit: "0",
    ForexProfits: "0",
    StockTotalbal: "0",
    StockDeposit: "0",
    StockProfits: "0",
    Totalbal: "0",
  });

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  const db = getFirestore(app);

  //Get user data
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const colRef = collection(db, "users");
        const q = query(colRef, where("uid", "==", uid));
        onSnapshot(q, (snapshot) => {
          snapshot.docs.forEach((doc) => {
            setUserData(doc.data());
          });
        });
      } else {
      }
    });
  }, [page, auth, db]);

  return (
    <UserInfo.Provider value={[userData, setUserData]}>
      <qrData.Provider value={[codeImage, setCodeImage]}>
        <ethData.Provider value={[ethImg, setEthImg]}>
          <dogeData.Provider value={[dogeImg, setDogeImg]}>
            <usdtData.Provider value={[usdtImg, setUsdtImg]}>
              <tronData.Provider value={[tronImg, setTronImg]}>
                <PageContext.Provider value={[page, setPage]}>
                  {props.children}
                </PageContext.Provider>
              </tronData.Provider>
            </usdtData.Provider>
          </dogeData.Provider>
        </ethData.Provider>
      </qrData.Provider>
    </UserInfo.Provider>
  );
};
export default PageProvider;
