import midstar from "../images/midstar.svg";
import stars from "../images/stars.svg";
import star from "../images/star.svg";
import flare from "../images/flare.svg";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import load from "../images/load.gif";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { motion } from "framer-motion";

const Signin = () => {
  const Navigate = useNavigate();

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth();

  //states
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [loadValue, setLoadValue] = useState("No");

  const signInBtn = () => {
    const signIn = document.getElementById("signIn");
    signIn.addEventListener("click", (e) => {
      e.preventDefault();
      setLoadValue("Yes");

      signInWithEmailAndPassword(auth, mail, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          setLoadValue("No");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const mess = document.getElementById("logMessage");
          mess.innerHTML = errorCode;
          setLoadValue("No");
        });
    });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        // direct to dashboard
        Navigate("/Dashboard");
      } else {
      }
    });
  });

  const validateMail = () => {
    const mail = document.getElementById("mail").value;
    const mailWarn = document.getElementById("mailWarn");

    const checkMail = (mail) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(mail).toLowerCase());
    };
    if (checkMail(mail)) {
      mailWarn.classList.replace("hidden", "block");
      mailWarn.innerHTML = "Valid Mail";
      mailWarn.style.color = "green";
      setMail(mail);
    } else {
      mailWarn.classList.replace("hidden", "block");
      mailWarn.innerHTML = "Invalid Mail";
      mailWarn.style.color = "red";
    }
  };

  const handlePword = (e) => {
    const pword = e.target.value;
    setPassword(pword);
  };

  return (
    <motion.div
      initial={{ x: 100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -100, opacity: 0 }}
      transition={{ type: "spring", stiffness: 80, duration: 0.2 }}
    >
      <div className=" px-3 md:px-3 flex flex-col mb-[4em] text-white justify-center items-center relative w-full h-full">
        <img src={star} className=" -z-10 absolute left-0 top-[-20vh]" alt="" />
        <img src={midstar} className=" -z-10 centered top-[0vh]" alt="" />
        <img
          src={stars}
          className=" -z-10 absolute right-0 top-[-20vh]"
          alt=""
        />
        <img src={flare} className=" mt-28" alt="" />
        <p className=" font-Lato font-semibold text-[32px]">
          Log into your account
        </p>
        <p className=" font-Open-Sans font-normal text-base">
          Welcome Back! Please Enter Your Details.
        </p>
        <div className=" flex justify-center items-center flex-col mt-8">
          <label
            className=" font-Open-Sans text-sm text-left w-full font-normal"
            for="first-name"
          >
            Email
          </label>
          <input
            type="text"
            onInput={validateMail}
            id="mail"
            placeholder="Enter your email"
            class=" h-[45px] w-[350px] glower mt-2 border border-[#03AAC74D] z-50 bg-[#121212] mb-6 text-white font-Open-Sans font-normal placeholder:font-Open-Sans text-sm rounded-[20px] block p-2.5"
          />
          <p
            id="mailWarn"
            className=" capitalize hidden mr-auto font-Lato text-xs mt-1 text-[rgba(255,255,255,0.9)]"
          >
            Please fill in your Email
          </p>
          <label
            className=" font-Open-Sans text-sm text-left w-full font-normal"
            for="first-name"
          >
            Password
          </label>
          <input
            type="password"
            onInput={handlePword}
            placeholder="Enter your Password"
            class=" h-[45px] w-[350px] mt-2 border border-[#03AAC74D] z-50 bg-[#121212] mb-6 text-white font-Open-Sans font-normal placeholder:font-Open-Sans text-sm rounded-[20px] block p-2.5"
          />
          <p
            id="logMessage"
            className="block mr-auto text-[#a7a7a7] font-Lato -mt-5 text-sm"
          ></p>
          <label className="flex items-center text-[#03AAC7] w-full">
            <Link to="/Forgot-Password">
              <span className="ml-auto text-sm font-Lato font-semibold">
                Forgot password
              </span>
            </Link>
          </label>
          <button
            id="signIn"
            onClick={signInBtn}
            className=" w-full flex h-[45px] mt-6 rounded-[20px] bg-[#03AAC7] hover:bg-opacity-75 transition-all justify-center font-Lato text-base text-white font-semibold items-center"
          >
            {loadValue === "No" && "Sign In"}
            {loadValue === "Yes" && (
              <img src={load} className=" w-6 h-6" alt="" />
            )}
          </button>
          <p className=" w-full text-center mt-4 font-Open-Sans text-sm text-[#FFFFFFB2]">
            Don’t have an account?
            <Link to="/Sign-Up">
              <span className=" font-Lato font-semibold text-[#03AAC7]">
                Sign Up
              </span>
            </Link>
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default Signin;
