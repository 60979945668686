import { motion } from "framer-motion";
import Footer from "../comps/footer";
import Navbar from "../comps/navbar";
import midstar from "../images/midstar.svg";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <motion.div
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -100, opacity: 0 }}
        transition={{ type: "spring", stiffness: 80, duration: 0.2 }}
      >
        <img src={midstar} className=" -z-10 centered top-[0vh]" alt="" />
        <div className=" md:px-0 flex flex-col justify-center items-center space-y-7 text-white">
          <span className=" bg-[#03AAC7] text-black py-1 px-4 font-Open-Sans text-sm md:text-base font-normal rounded-[40px] mt-[3em]">
            Contact us
          </span>
          <p className=" font-Lato text-[32px] md:text-5xl text-white font-semibold leading-9 text-center md:px-[20%]">
            How can we help?
          </p>
          <p className=" text-lg font-Open-Sans font-normal text-center leading-6 md:px-[15%]">
            Get in touch with our support teams for onboarding support, or
            product questions.
          </p>
          <button className=" bg-[#03AAC7] px-8 py-3 rounded-[30px] font-Lato text-lg md:text-xl font-normal glower">
            Get started
          </button>
        </div>
        <div className=" px-3 md:px-16 z-10 pt-8 bg-[#121212] -translate-y-14 text-white">
          <div className=" w-full p-3 md:p-8">
            <div className=" w-full flex flex-col md:flex-row justify-between mb-6">
              <span className=" w-full md:w-[48%]">
                <label
                  className=" font-Lato text-base font-medium"
                  for="first-name"
                >
                  First name
                </label>
                <input
                  type="text"
                  id="first-name"
                  placeholder="John Doe"
                  class=" h-[45px] mt-2 border border-[#EAEBF01A] bg-[#EAEBF01A] text-white font-Lato font-normal placeholder:font-Lato text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </span>
              <span className=" w-full md:w-[48%]">
                <label
                  className=" font-Lato text-base font-medium"
                  for="first-name"
                >
                  Email Address
                </label>
                <input
                  type="text"
                  id="mail"
                  placeholder="Johndoe@mail.com"
                  class=" h-[45px] mt-2 border border-[#EAEBF01A] bg-[#EAEBF01A] text-white font-Lato font-normal placeholder:font-Lato text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </span>
            </div>
            <label
              className=" font-Lato text-base font-medium"
              for="first-name"
            >
              Topic of complaint
            </label>
            <input
              type="text"
              id="phone"
              placeholder="Enter topic of complaint"
              class=" h-[45px] mt-2 border border-[#EAEBF01A] bg-[#EAEBF01A] mb-6 text-white font-Lato font-normal placeholder:font-Lato text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />

            <label className=" font-Lato text-base font-medium" for="Message">
              Message
            </label>
            <textarea
              id="message"
              rows={6}
              placeholder="Hi! We are Bitfin...."
              class="border-[#EAEBF01A] bg-[#EAEBF01A] border mb-4 font-Lato font-normal placeholder:font-Lato text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            ></textarea>

            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2 text-base font-Lato font-medium">
                I agree with Bitfin Privacy Policy
              </span>
            </label>

            <button className=" w-full flex h-[45px] mt-6 rounded-[8px] bg-[#03AAC7] hover:bg-opacity-75 transition-all justify-center items-center">
              <p className=" font-Lato text-base text-white">Send Message</p>
            </button>
          </div>
        </div>
        <Footer />
      </motion.div>
    </>
  );
};

export default ContactPage;
